import withoutShowdow from "../Images/without_shadow.png";
import { useState } from "react";

import {Navbar, NavDropdown, Nav, Form} from 'react-bootstrap';

const _Sidepanel = ({ state, control, slide }) => {

  return <>
    <div className=" d-none d-lg-block bg-white pt-2 pr-3 pl-3 min-vh-100" style={{width:"300px"}}>
    <Navbar.Brand href="/home" className="ml-3 text-dark" >
   <img 
         alt=""
         src={withoutShowdow}
         width="40"
         height="40"
         className="d-inline-block align-top"
       />{' '}
       <h1 style={{fontSize:'30px'}}  className="d-inline-block align-top">SAVYN</h1>
   </Navbar.Brand>
 {/* <ul className=" list-group  " style={{cursor:"pointer"}}></ul> */}
   <div className=" d-none d-lg-block bg-white p-3 min-vh-100" style={{width:"256px"}}>
<ul className=" list-group  ">
    
<li className="list-unstyled m-3  "> <a onClick={()=>{
      control.goWhere("therapistHome");}}  
      class=" text-dark d-lg-block listItem "><i class="fa fa-home p-1 " aria-hidden="true"></i> My clients </a>
</li>

    <li className="list-unstyled m-3  "> <a onClick={()=>{
      control.goWhere("breathExercise");}}  
      class=" text-dark d-lg-block listItem "><i class="fa fa-info p-1 " aria-hidden="true"></i> My information </a></li>

<li className="list-unstyled m-3  "> <a onClick={()=>{
      control.goWhere("breathExercise");}}  
      class=" text-dark d-lg-block listItem "><i class="far fa-play-circle"></i> start a session </a></li>

    {/* <li className="list-unstyled m-3 " ><a  onClick={()=>{
      control.goWhere("dashboards");}}
      class="text-dark d-lg-block listItem"><i class="fa fa-info-circle p-1" aria-hidden="true"></i> Dashboards </a></li>

     */}
    <NavDropdown.Divider />
        <Nav.Link onClick={()=>{
        control.clearState()
        control.goWhere('homePage')
      }}>Sign out</Nav.Link> 
</ul>
</div>
</div>
</>
};
export default _Sidepanel;

