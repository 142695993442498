import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Form, Row, Table } from "react-bootstrap";
import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";


const InitialBox = ({ index, event, answers= [], setAnswers=()=>{} }) => {
  return (
    <tr>
      <td>{event}</td>
      <td style={{ textAlign: "center" }}>
        <input
          inline
          name={"radio" + index}
          label=""
          id={"inline-radio-" + index}
          onChange={(e) => {
            setAnswers({ ...answers, [index]: e.target.value });
          }}
        />
      </td>
    </tr>
  );
};


const PatientChecklist = ({ state, control, slide }) => {
  const [answers, setAnswers] = useState({});
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        {/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left" style={{ width: "800px" }}>
          <h3>EMDR Consent</h3>
          <br />
          <Form>
            <Form.Label>
              Please read each statement carefully. If you agree with the
              statement, please write your initials in the space provided. If
              you have any questions or concerns, please talk with your
              therapist before completing this checklist.
            </Form.Label>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Initial</th>
                </tr>
              </thead>
              <tbody>
                <InitialBox
                  event={
                    "I am committed/dedicated to both my own safety and treatment."
                  }
                  index={1}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={"I have skills to handle high levels of emotion."}
                  index={2}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I was able to do the resourcing development and installation."
                  }
                  index={3}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have imaginal resources that I can use to calm and comfort myself."
                  }
                  index={4}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have an adequate support system that includes, but is not limited to, my therapist."
                  }
                  index={5}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I am not taking medications in the Benzodiazepine class. (e.g., end in “pam”)- AND Either my medication is effective or I am stable without medication."
                  }
                  index={6}
                />
                <InitialBox
                  event={
                    "My health and safety are not in jeopardy from substance use/abuse; am not in active addiction."
                  }
                  index={7}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "Self-harming behaviors are not my primary method of coping with affect/emotions/relationship troubles. If this was/is an issue for me, have adequately addressed it in therapy."
                  }
                  index={8}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={"I do not feel suicidal."}
                  index={8}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have not been diagnosed with a dissociative disorder"
                  }
                  index={9}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have been given the screening, Dissociative Experience Scale ll (DES II) for dissociative disorder and have discussed the results with my therapist."
                  }
                  index={10}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I am not involved in an active legal case -OR- I am involved in a legal case. I have been informed that by reprocessing the material in question, my legal testimony may be impaired."
                  }
                  index={11}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I do not have any concerns about my safety with the relationships I am in, either emotionally or physically."
                  }
                  index={12}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have never been hospitalized for self-harm, suicidal ideation or behaviour."
                  }
                  index={13}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have not actively engaged in any sort of addiction at a clinical level. Addictions can be substances, eating disorders etc."
                  }
                  index={14}
                  answers={answers}
                  setAnswers={setAnswers}
                />
                <InitialBox
                  event={
                    "I have read, understood and signed an Informed Consent to begin EMDR sessions"
                  }
                  index={15}
                  answers={answers}
                  setAnswers={setAnswers}
                />
              </tbody>
            </Table>
          </Form>
          <Form>
            <button
              onClick={async () => {
                await addJSON({
                  variables: {
                    json: JSON.stringify({
                      startTime,
                      endTime: new Date().toISOString(),
                      type: slide?.type,
                      slide: slide?._slide,
                      answers,
                    }),
                  },
                });
                control.goSlide(slide.complete);
              }}
              type="button"
              className="btn btn-block text-center btn-lg mb-2 btnStandard"
            >
              {slide.complete.title}
            </button>
            <div style={{ paddingTop: "50px" }}></div>
          </Form>
        </div>
      </Row>
    </>
  );
};

export default PatientChecklist;
