const TestTemplate = ({ state, control, slide }) => {


  return (
    <div>
        This is a test 
        hello world

        <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4 "
          
            onClick={() => {
              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title}
          </button>
    </div>
  );
};
export default TestTemplate;
