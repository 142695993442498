import {
  Container,
  Form,
} from "react-bootstrap";

import {useQuery, useMutation } from "@apollo/client";
import gqlQuery from '../GQL';
import {  useState } from "react";

const GetName = ({ state, control, slide }) => {

  const [createAccount] = useMutation(gqlQuery.createAccount);
  const [login] = useMutation(gqlQuery.login);




  let [name, setName] = useState(
    localStorage.getItem("user_name") ? localStorage.getItem("user_name") : ""
  );
  let [password, setPassword] = useState(
    localStorage.getItem("user_password")
      ? localStorage.getItem("user_password")
      : ""
  );
  let [emailAddress, setEmailAddress] = useState(
    localStorage.getItem("user_email") ? localStorage.getItem("user_email") : ""
  );

  return (
    <>
      {/* <Navbar state={state} control={control} slide={slide}/> */}
      <div className="signUp">
        <Container className="d-flex align-items-center justify-content-center vh-100 mh-100 ">
          <div className=" flex-column ">
            <div className="box">
              <h1 className="mb-5">Sign Up</h1>
              <button onClick={() => {}} className="userBtn">
                I'm a user
              </button>
              <button className="therapistBtn">I'm a therapist</button>

              <Form>
                <Form.Group controlId="userName">
                  <Form.Control
                    className="mt-4"
                    type="text"
                    value={name}
                    placeholder="Your prefered name in the app"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <Form.Control
                    value={emailAddress}
                    type="email"
                    placeholder="Email address"
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                  <Form.Control
                    value={password}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Group>

                <button
                  onClick={async () => {
                    let {data} = await createAccount({
                      variables: {
                        name,password,email:emailAddress
                      },
             
                    });

      

            
                   
                    if (data.createAccount.id===""){
                      let {data} = await login({
                        variables: {
                          password,email:emailAddress
                        },
                 
                      });
                
                      

                      if (data.login.id===""){

                        alert("Password Failed");
                        return;
                      }
                      control.setStateH({
                        ...data.login.json,
                        name
                      });
                      localStorage.setItem("token", data.login.id);
              
                
               
                      localStorage.setItem("user_name", name);
                      localStorage.setItem("user_password", password);
                      localStorage.setItem("user_email", emailAddress);
                    } else {
                      control.setStateH({
                        ...data.createAccount.json,
                        name
                      });
                      localStorage.setItem("token", data.createAccount.id);
              
          
                      localStorage.setItem("user_name", name);
                      localStorage.setItem("user_password", password);
                      localStorage.setItem("user_email", emailAddress);
                    }



          



       
                  }}
                  type="button"
                  className="btn btn-block border text-center btn-md mb-2 signupBtn"
                >
                  Sign up
                </button>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default GetName;
