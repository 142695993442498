import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import { useState } from "react";

const VisitSafespace = ({ state, control, slide }) => {
  const [selectEntry, setSelectEntry] = useState(undefined);
  const [selectAudio, setSelectAudio] = useState(undefined);
  const [selectAudioEntry, setSelectAudioEntry] = useState(undefined);

  if (!state.audio) {
    state.audio = [];
  }
  if (!state.entries) {
    state.entries = [];
  }
  if (!state.audioEntries) {
    state.audioEntries = [];
  }

  /* Add written entry to calm place library*/
  if (selectEntry !== undefined) {
    return (
      <>
        <Navbar state={state} control={control} slide={slide} />
        <Row className="bg-img min-vh-100">
          <Sidepanel state={state} control={control} slide={slide} />
          <div className="box text-left">
            {/* Title of calm place written entries*/}
            <h3 className="text-center my-4">Text Entry</h3>
            <br />

            <textarea
              onChange={(e) => {}}
              className="form-control border border-secondary mt-n3"
              name=""
              rows="12"
              value={state.entries[selectEntry].entry}
              readOnly={true}
            ></textarea>

            <button
              type="button"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              // onClick={()=>{
              //   setSelectAudio(undefined);
              //   setSelectEntry(undefined);
              // }}
              onClick={() => {
                control.goWhere("howSafeDoYouFeel");
              }}
            >
              {state.savePosition ? "Next" : "Next"}

              {/* Next Hello */}
            </button>
          </div>
        </Row>
      </>
    );
  }

  /* Add audio to calm place library*/
  if (selectAudio !== undefined) {
    let audioURL = state.audio[selectAudio];
    return (
      <>
        <Navbar state={state} control={control} slide={slide} />
        <Row className="bg-img min-vh-100">
          <Sidepanel state={state} control={control} slide={slide} />
          <div className="box text-left" style={{ width: "800px" }}>
            <h3 className="mb-4">Calm Place Audio Entry</h3>
            <p className="mb-4"></p>

            <div className="m-5">
              <audio controls>
                <source src={audioURL} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>

            <button
              type="button"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={() => {
                control.goWhere("howSafeDoYouFeel");
                setSelectAudio(undefined);
                setSelectEntry(undefined);
              }}
            >
              {state.savePosition ? "Next" : "Next"}
            </button>
          </div>
        </Row>
        {/* </div>
      </div> */}
      </>
    );
  }

  /* Add audio to calm place library*/
  if (selectAudioEntry !== undefined) {
    let audioURL = state.audioEntries[selectAudioEntry].audio;
    console.log("Test Audio Entry: ");
    console.log(audioURL);

    return (
      <>
        <Navbar state={state} control={control} slide={slide} />
        <Row className="bg-img min-vh-100">
          <Sidepanel state={state} control={control} slide={slide} />
          <div className="box text-left" style={{ width: "800px" }}>
            <h3 className="mb-4">Calm Place Audio Entry</h3>
            <p className="mb-4"></p>

            <div className="m-5">
              <audio controls>
                <source src={audioURL} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>

            <button
              type="button"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={() => {
                control.goWhere("howSafeDoYouFeel");
                setSelectAudio(undefined);
                setSelectEntry(undefined);
              }}
            >
              {state.savePosition ? "Next" : "Next"}
            </button>
          </div>
        </Row>
        {/* </div>
      </div> */}
      </>
    );
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        {/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3>Calm Place Library</h3>
          <br />

          {state.audio.map((e, index) => {
            return (
              <div
                key={"audio" + index}
                className="btn btn-block  text-left btn-lg"
                onClick={() => {
                  setSelectAudio(index);
                  setSelectEntry(undefined);
                  setSelectAudioEntry(undefined);
                }}
              >
                <i
                  className="fas fa-microphone mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}
                ></i>

                {e.title}
                {/*Calm Place recording {index+1}*/}
              </div>
            );
          })}

          {state.audioEntries.map((e, index) => {
            return (
              <div
                key={"audioEntries" + index}
                className="btn btn-block text-left btn-lg"
                onClick={() => {
                  setSelectAudio(undefined);
                  setSelectEntry(undefined);
                  setSelectAudioEntry(index);
                }}
              >
                <i
                  className="fas fa-microphone mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}
                ></i>

                {e.title}
                {/*Calm Place audio recording {index+1}*/}
              </div>
            );
          })}

          {state.entries.map((e, index) => {
            return (
              <div
                key={"entries" + index}
                className="btn btn-block  text-left btn-lg"
                onClick={() => {
                  setSelectAudio(undefined);
                  setSelectEntry(index);
                  setSelectAudioEntry(undefined);
                  console.log(index);
                }}
              >
                <i
                  className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}
                ></i>

                {e.title}
              </div>
            );
          })}

          <button
            type="submit"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={() => {
              control.goWhere("modifySafeSpace");
            }}
          >
            Add a Calm Place
          </button>
          <button
            type="submit"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={() => {
              control.goWhere("preRecordedCalmPlace");
            }}
          >
            Pre-recorded calm places
          </button>

          {/* <button 
          type="submit" 
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
          onClick = {()=>{
                control.goWhere("homePage")
              }}
          >
Home
            
            </button> */}
        </div>
        {/* </div>*/}
      </Row>
    </>
  );
};
export default VisitSafespace;
