import Navbar from "../Components/Navbar";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const RecordAudioButton = ({ slide, control }) => {
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    setAudioDetails(data);
  };
  const handleAudioUpload = (file) => {
    const objectURL = URL.createObjectURL(file);
    control.saveContainerAudio(objectURL);
   //control.goSlide(slide.quit);
  };

  //comment
  const handleRest = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  return (
    <>
      <Recorder
        record={true}
        title={"New recording"}
        audioURL={audioDetails.url}
        handleAudioStop={(data) => handleAudioStop(data)}
        handleAudioUpload={(data) => handleAudioUpload(data)}
        handleRest={() => handleRest()}
      />
    </>
  );
};

const RecordContainerAudio = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  const [title, setTitle] = useState("");
  const [audio, setAudio] = useState("");

  const changeSlide = async (save) => {
    if (save) {
      await addJSON({
        variables: {
          json: JSON.stringify({
            answer: { audio, title },
            startTime,
            endTime: new Date().toISOString(),
            type: slide?.type,
            slide: slide?._slide,
          }),
        },
      });
    } else {
      await addJSON({
        variables: {
          json: JSON.stringify({
            startTime,
            endTime: new Date().toISOString(),
            type: slide?.type,
            slide: slide?._slide,
          }),
        },
      });
    }
  };

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center">{slide.title}</h3>
          <br />
          <p>
          Step 1: Click the microphone button to record audio <br />
          Step 2: Click the stop button when the recording is finished <br />
          Step 3: Click the upload button to save the audio <br />
          Step 4: Enter a name for the container <br />
          Step 5: Click the save button to continue <br />          
        </p>

          <div className="text-center my-3"></div>
          <div className="text-center my-3">
            <RecordAudioButton
              slide={slide}
              control={control}
              onChange={(e) => setAudio(e.target.value)}
            />
          </div>

          <p>Give it a name. Choose a cue word that best describes it.</p>
          <textarea
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className="form-control border border-secondary mt-3"
            name=""
            rows="1"
          ></textarea>

          <div className="float-right mt-4">
            {slide.quit ? (
              <button
                onClick={async() => {
                  await changeSlide(false);
                  control.goSlide(slide.quit);
                }}
                type="button"
                className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
              >
                Quit
              </button>
            ) : (
              <></>
            )}

            <button
              onClick={async () => {
                await changeSlide(true);
                control.saveContainerAudioEntry(audio, title);
                control.goSlide(slide.save);
              }}
              type="button"
              className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
            >
              Save
            </button>

            {slide.complete ? (
              <button
                type="button"
                className="btn btn-block text-center btn-lg mt-4 btnStandardMini"
                onClick={async() => {
                  await changeSlide(false);
                  control.goSlide(slide.complete);
                }}
              >
                Next
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};
export default RecordContainerAudio;
