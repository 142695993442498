import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Form, Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";


const MultiSelect = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [selectState, setSelectState] = useState({});
  const [writeYourOwn, setWriteYourOwn] = useState("");

  const changeSlide = async (save) => {
    await addJSON({
      variables: {
        json: JSON.stringify({
          answer: { selectState, writeYourOwn },
          startTime,
          endTime: new Date().toISOString(),
          type: slide?.type,
          slide: slide?._slide,
        }),
      },
    });
  };

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center mb-4">{slide.title}</h3>
          <p>{slide.description}</p>
          <br />

          {slide.buttons.map((button, index) => {
            return (
              <div
                key={"button:" + index}
                onClick={(e) => {
                  var other = { ...selectState };
                  other[button.title] = !selectState[button.title];
                  setSelectState(other);
                }}
                className="btn btn-block btn-lg mb-2 mx-auto text-center btnStandard"
              >
                {selectState[button.title]
                  ? button.title + " -> Selected"
                  : button.title}
              </div>
            );
          })}

          {slide.writeYourOwn ? (
            <>
              <Form>
                <Form.Group controlId="userName">
                  <Form.Label>Write You Own:</Form.Label>
                  <Form.Control type="text" placeholder="Write Your Own" onChange={(e)=>{
                    setWriteYourOwn(e.target.value);
                  }} />
                </Form.Group>
              </Form>

              <div
                onClick={async () => {
                  await changeSlide();
                  control.goSlide(slide.writeYourOwn);
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                {slide.writeYourOwn.title}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
};
export default MultiSelect;
