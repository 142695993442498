import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Form, Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

import { RowZeroHundred } from "./Helpers";

const PatientHistory = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [answers, setAnswers] = useState({});
  const [addJSON] = useMutation(gqlQuery.addJSON);
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        {/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box" style={{ width: "850px" }}>
          {/* <div className=" flex-column w-100"> */}

          <h3>DES Assessment</h3>
          <Form className="text-left">
            <Form.Label className="mb-5">
              This questionnaire consists of twenty‐eight questions about
              experiences that you may have in your daily life. We are
              interested in how often you have these experiences. It is
              important, however, that your answers show how often these
              experiences happen to you when you are not under the influence of
              alcohol or drugs. To answer the questions, please determine to
              what degree the experience described in the question applies to
              you and select the number to show what percentage of the time you
              have the experience. 100% means ‘always’, 0% means ‘never’ with
              10% increments in between. This assessment is not intended to be a
              diagnosis. If you are concerned about your results in any way,
              please speak with a qualified health professional.
            </Form.Label>

            <Form.Group className="mb-5" controlId="desAssessment1">
              <Form.Label>
                1. Some people have the experience of driving a car and suddenly
                realizing that they don't remember what has happened during all
                or part of the trip. Select a number to show what percentage of
                the time this happens to you
              </Form.Label>

              <RowZeroHundred
                index={0}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment2">
              <Form.Label>
                2. Some people find that sometimes they are listening to someone
                talk and they suddenly realize that they did not hear all or
                part of what was said. Select a number to show what percentage
                of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={1}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment3">
              <Form.Label>
                3. Some people have the experience of finding themselves in a
                place and having no idea how they got there. Select a number to
                show what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={2}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment4">
              <Form.Label>
                4. Some people have the experience of finding themselves dressed
                in clothes that they don't remember putting on. Select a number
                to show what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={3}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment5">
              <Form.Label>
                5. Some people have the experience of finding new things among
                their belongings that they do not remember buying. Select a
                number to show what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={4}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment6">
              <Form.Label>
                6. Some people sometimes find that they are approached by people
                that they do not know who call them by another name or insist
                that they have met them before. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={5}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment7">
              <Form.Label>
                7. Some people sometimes have the experience of feeling as
                though they are standing next to themselves or watching
                themselves do something as if they were looking at another
                person. Select a number to show what percentage of the time this
                happens to you
              </Form.Label>
              <RowZeroHundred
                index={6}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment8">
              <Form.Label>
                8. Some people are told that they sometimes do not recognize
                friends or family members. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={7}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment9">
              <Form.Label>
                9. Some people find that they have no memory for some important
                events in their lives (for example, a wedding or graduation).
                Select a number to show what percentage of the time this happens
                to you
              </Form.Label>
              <RowZeroHundred
                index={8}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment10">
              <Form.Label>
                10. Some people have the experience of being accused of lying
                when they do not think that they have lied. Select a number to
                show what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={9}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment11">
              <Form.Label>
                11. Some people have the experience of looking in a mirror and
                not recognizing themselves. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={10}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment12">
              <Form.Label>
                12. Some people sometimes have the experience of feeling that
                other people, objects, and the world around them are not real.
                Select a number to show what percentage of the time this happens
                to you
              </Form.Label>
              <RowZeroHundred
                index={11}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment13">
              <Form.Label>
                13. Some people sometimes have the experience of feeling that
                their body does not belong to them. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={12}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment14">
              <Form.Label>
                14. Some people have the experience of sometimes remembering a
                past event so vividly that they feel as if they were reliving
                that event. Select a number to show what percentage of the time
                this happens to you
              </Form.Label>
              <RowZeroHundred
                index={13}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment15">
              <Form.Label>
                15. Some people have the experience of not being sure whether
                things that they remember happening really did happen or whether
                they just dreamed them. Select a number to show what percentage
                of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={14}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment16">
              <Form.Label>
                16. Some people have the experience of being in a familiar place
                but finding it strange and unfamiliar. Select a number to show
                what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={15}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment17">
              <Form.Label>
                17. Some people find that when they are watching television or a
                movie they become so absorbed in the story that they are unaware
                of other events happening around them. Select a number to show
                what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={16}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment18">
              <Form.Label>
                18. Some people sometimes find that they become so involved in a
                fantasy or daydream that it feels as though it were really
                happening to them. Select a number to show what percentage of
                the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={17}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment19">
              <Form.Label>
                19. Some people find that they are sometimes able to ignore
                pain. Select a number to show what percentage of the time this
                happens to you
              </Form.Label>
              <RowZeroHundred
                index={18}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment20">
              <Form.Label>
                20. Some people find that they sometimes sit staring off into
                space, thinking of nothing, and are not aware of the passage of
                time. Select a number to show what percentage of the time this
                happens to you
              </Form.Label>
              <RowZeroHundred
                index={19}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment21">
              <Form.Label>
                21. Some people sometimes find that when they are alone they
                talk out loud to themselves. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={20}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment22">
              <Form.Label>
                22. Some people find that in one situation they may act so
                differently compared with another situation that they feel
                almost as if they were different people. Select a number to show
                what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={21}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment23">
              <Form.Label>
                23. Some people sometimes find that in certain situations they
                are able to do things with amazing ease and spontaneity that
                would usually be difficult for them (for example, sports, work,
                social situations, etc.). Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={22}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment24">
              <Form.Label>
                24. Some people sometimes find that they cannot remember whether
                they have done something or have just thought about doing that
                thing (for example, not knowing whether they have just mailed a
                letter or have just thought about mailing it). Select a number
                to show what percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={23}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment25">
              <Form.Label>
                25. Some people find evidence that they have done things that
                they do not remember doing. Select a number to show what
                percentage of the time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={24}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment26">
              <Form.Label>
                26. Some people sometimes find writings, drawings, or notes
                among their belongings that they must have done but cannot
                remember doing. Select a number to show what percentage of the
                time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={25}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment27">
              <Form.Label>
                27. Some people find that they sometimes hear voices inside
                their head that tell them to do things or comment on things that
                they are doing. Select a number to show what percentage of the
                time this happens to you
              </Form.Label>
              <RowZeroHundred
                index={26}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
            <Form.Group className="mb-5" controlId="desAssessment28">
              <Form.Label>
                28. Some people sometimes feel as if they are looking at the
                world through a fog so that people or objects appear far away or
                unclear. Select a number to show what percentage of the time
                this happens to you
              </Form.Label>
              <RowZeroHundred
                index={27}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Form.Group>
          </Form>

          <Form>
            <button
              onClick={async () => {
                await addJSON({
                  variables: {
                    json: JSON.stringify({
                      startTime,
                      endTime: new Date().toISOString(),
                      type: slide?.type,
                      slide: slide?._slide,
                      answers,
                    }),
                  },
                });
                control.goSlide(slide.complete);
              }}
              type="button"
              className="btn btn-block text-center btn-lg mb-2 btnStandard"
            >
              {slide.complete.title}
            </button>
            <div style={{ paddingTop: "50px" }}></div>
          </Form>
        </div>
      </Row>
    </>
  );
};

export default PatientHistory;
