import State from './State';

import AudioPlay from "../Templates/AudioPlaySingle";
import WriteEntry from "../Templates/WriteEntry";
import RecordAudioEmdr from "../Templates/RecordAudioEmdr";
import RecordEmdrAudio from "../Templates/RecordEmdrAudio";
import ReplayEmdrAudio from "../Templates/ReplayEmdrAudio";
import RecordAudioContainer from "../Templates/RecordAudioContainer";
import VisitEmdrAudio from "../Templates/VisitEmdrAudio";
import HomePage from "../Templates/Homepage";
import Assessment from "../Templates/Assessment";
import BDIAssessment from "../Templates/BDIAssessment";
import TrumaCreator from "../Templates/TrumaCreator";
import Counter from "../Templates/Counter";
import Video from "../Templates/Video";
import ScaleSlide from "../Templates/ScaleSlide";
import GetName from "../Templates/GetName";
import EMDRList from '../Templates/EMDRList';
import EMDRSelector from "../Templates/EMDRSelector";
import EMDRJournal from "../Templates/EMDRJournal";
import ReviewEmdrJournal from '../Templates/ReviewEmdrJournal';
import TestTemplate from "../Templates/TestTemplate";
import PatientDES from "../Templates/PatientDES";
import PatientChecklist from "../Templates/PatientChecklist";
import SelectMood from "../Templates/SelectMood";
import EndSession from '../Templates/EndSession';
import SetCalm from "../Templates/SetCalm";
import YesNo from "../Templates/YesNo";
import SetSUDS from "../Templates/SetSUDS";
import VisitSafespace from "../Templates/VisitSafespace";
import Desc from "../Templates/Desc";
import MultiButton from "../Templates/MultiButton";
import MultiSelect from "../Templates/MultiSelect";
import MultiAudio from "../Templates/MultiAudio";
import JournalEntries from "../Templates/JournalEntries";
import PatientHistory from "../Templates/PatientHistory";
import Consent from "../Templates/Consent";
import WriteContainer from "../Templates/WriteContainer";
import WriteWet from "../Templates/WriteWet";
import VisitContainer from "../Templates/VisitContainer";
import SignUp from "../Templates/SignUp";
import TherapistHomepage from "../Templates/TherapistHomepage";
import DashboardButtons from "../Templates/DashboardButtons";
import EMDRVideo from '../EMDR';
import DropdownAudio from '../Templates/DropdownAudio';
import Testing from '../Templates/Testing';
import VisitCalmPlace from '../Templates/VisitCalmPlace';
import PositiveBelief from "../Templates/PositiveBelief";
import ProcessTrauma from '../Templates/ProcessTrauma';
import ProcessMemory from '../Templates/ProcessMemory';


import {setMegaState,getMegaState} from "../Helper/MegaState";


function DetermineIfFirstTime({ state, control, goFinish, where }) {
    if (goFinish) {
      state.bookMark["emdrReturn"] = goFinish;
    }
  
    if (state.notFirstTime) {
      return (
        <VisitSafespace
          state={state}
          control={control}
          slide={where["createSafeSpace"]}
        />
      );
    } else {
      return (
        <YesNo state={state} control={control} slide={where["haveSafeSpace"]} />
      );
    }
  }


function InnerApp({ history, state, setState, slide, setSlide, where }) {  
    const displayTemplate = slide.type;
  
    const control = {
      createJSON: () =>{
        return JSON.stringify(state);
      },
      setEMDRSession: (text) => {
        let _state = new State(state);
        _state.EMDRSession = text;
        setState(_state);
      },
      // setWETSession: (text) => {
      //   let _state = new State(state);
      //   _state.WETSession = text;
      //   setState(_state);
      // },
      
      clearState: () => {
        localStorage.getItem("token", undefined);
        localStorage.setItem("debugState", undefined);
        setState(new State());
        
      },
      addName: (name) => {
        let _state = new State(state);     
        _state.name = name;
        setState(_state, true);
      },
      increaseSession:()=>{
        let _state = new State(state);
        _state.sessionCount = _state.sessionCount+1;
        setState(_state);
      },

      toggleSideBar:(sideBarState)=>{
        let _state = new State(state);
        _state.toggleSideBar = sideBarState;
        setState(_state);
      },
      cpEntry:(cpEntryState) =>{
        let _state = new State(state);
        _state.cpEntry=cpEntryState;
        setState(_state);
      },
  
      addTrigger: ({ trigger, noTrigger }) => {
        if (noTrigger === "") {
          noTrigger = "Unnamed Trigger";
        }
        if (trigger === "") {
          trigger = "Unnamed Trigger";
        }
  
        state.addEmdr({ trigger, noTrigger });
        setState(state);
      },
      setAssessment: (value) => {
        let _state = new State(state);
        _state.assessment = value;
        state.assessment = value;
        setState(_state);
      },
      setBDIAssessment: (value) => {
        let _state = new State(state);
        _state.bdiAssessment = value;
        state.bdiAssessment = value;
        setState(_state);
      },
      addWrittenExposure: (text, index) => {
        state.addWrittenExposure(text, index);
        setState(state);
      },
  
      saveContainer: (entry, title) => {
        if (!state.containers) {
          state.containers = [];
        }
        state.containers.push({
          title,
          entry,
        });
        let _state = new State(state);
        setState(_state);
      },

      saveContainerAudioEntry: (audio, title) => {
        if (!state.containerAudioEntries) {
          state.containerAudioEntries = [];
        }
        state.containerAudioEntries.push({
          title,
          audio,
        });
        let _state = new State(state);
        setState(_state);
      },

      saveContainerAudio: (containerAudio) => {
        if (!state.containerAudio) {
          state.containerAudio = [];
        }
        // state.notFirstTime = true;
        state.containerAudio.push(containerAudio);
        setState(state);
      },


      saveEmdrAudio: (emdrAudio) => {
        if (!state.emdrAudio) {
          state.emdrAudio = [];
        }
        state.notFirstTime = true;
        state.emdrAudio.push(emdrAudio);
        setState(state);
      },

      setCustomAnswer: (answer) => {
        if (!state.posBelief) {
          state.posBelief = [];
        }
        state.posBelief.push(answer);
        let _state = new State(state);
        setState(_state);
      },

      setProcessTraumaTitle: (title) => {
        if (!state.processTraumaTitle) {
          state.processTraumaTitle = [];
        }
        state.processTraumaTitle.push(title);
        let _state = new State(state);
        setState(_state);
      },

      saveJournal: (date, distressingEvent, eventTrigger, image, belief, emotion, scan, distress ) => {
        if (!state.journals) {
          state.journals = [];
        }
        state.journals.push({
          date, 
          distressingEvent, 
          eventTrigger, 
          image, 
          belief, 
          emotion, 
          scan, 
          distress
        });
        let _state = new State(state);
        setState(_state);
      },

      saveWet: (entry, title) => {
        if (!state.wet) {
          state.wet = [];
        }
        state.wet.push({
          title,
          entry,
        });
        let _state = new State(state);
        setState(_state);
      },
  
      saveEntry: (entry, title) => {
        if (!state.entries) {
          state.entries = [];
        }
        state.notFirstTime = true;
        state.entries.push({
          title,
          entry,
        });
        setState(state);
      },

      saveAudioEntry: (audio, title) => {
        if (!state.audioEntries) {
          state.audioEntries = [];
        }
        state.notFirstTime = true;
        state.audioEntries.push({
          title,
          audio,
        });
        setState(state);
      },
  
      saveAudio: (audio) => {
        if (!state.audio) {
          state.audio = [];
        }
        state.notFirstTime = true;
        state.audio.push(audio);
        setState(state);
      },

      editEntry: (entry, index, title) => {
        if (!state.entries) {
          state.entries = [];
        }
  
        if (state.entries[index]) {
          state.entries[index] = { title, entry };
        }
        setState(state);
      },
      goAudioSafeSpace: (e, index) => {
        history.push("/safeSpaceAudio/" + index);
      },
      goTextSafeSpace: (e, index) => {
        history.push("/safeSpaceText/" + index);
      },
  
      setBookMark: (bookMark, slide) => {
        if (!state.bookMark) {
          state.bookMark = {};
        }
        state.bookMark[bookMark] = slide;
  
        let _state = new State(state);
        setState(_state);
      },
  
      goBookMark: (bookMark) => {
        if (state.bookMark) {
          if (state.bookMark[bookMark]) {
            control.goWhere(state.bookMark[bookMark]);
          }
        } else {
          control.goWhere("homePage");
        }
      },
  
      goCheckOut: () => {
        if (state.savePosition) {
          control.goWhere(state.savePosition);
          return;
        }
        control.goWhere("howSafeDoYouFeel");
      },
  
      goWhere: (string) => {
        let aSlide = where[string];
        if (aSlide.url) {
          history.push(aSlide.url);
        }
  
        localStorage.setItem(
          "debugState",
          JSON.stringify({
            slide: string,
            state,
          })
        );
        setSlide(string);
      },
  
      say: (text) => {
        var msg = new SpeechSynthesisUtterance();
        msg.text = text;
        window.speechSynthesis.speak(msg);
      },


      setStateH:(_state)=>{
        let aState = new State(_state);
        setState(aState);
      },
  
      goSlide: (object, vars) => {
        if (!object) {
          setSlide("noPage");
          return;
        }
  
        let aSlide = where[object.slide];
  
        if (!aSlide) {
          setSlide("noPage");
          return;
        }
  
        if (object.action) {

            if (object.action.type === "savePosition") {
              let _state = new State(state);
              _state.savePosition = object.action.slide;
              setState(_state);
            }
            if (object.action.type === "setTrigger") {
              let _state = new State(state);
              _state.EMDRSession = undefined;
              _state.emdrSessions.push({
                trigger: vars.trigger,
                noTrigger: vars.noTrigger,
                lastSession: 1,
              });
  
              setState(_state);
            }
            if (object.action.type === "setConsent") {
              let _state = new State(state);
              _state.consent = true;
              setState(_state);
            }
            if (object.action.type === "setBDIAssessment") {
              let _state = new State(state);
              _state.bdiassessment = true;
              setState(_state);
            }
          
        }
  
        if (aSlide.url) {
          history.push(aSlide.url);
        }
  
        if (object.bookmarks) {
          object.bookmarks.forEach((bookMark) => {
            control.setBookMark(bookMark.bookMark, bookMark.slide);
          });
        }
  
        if (object.bookMark) {
          if (state.bookMark[object.bookMark]) {
            const keyForBookMark = state.bookMark[object.bookMark];
            aSlide = where[keyForBookMark];
  
            localStorage.setItem(
              "debugState",
              JSON.stringify({
                slide: keyForBookMark,
                state,
              })
            );
            setSlide(keyForBookMark);
            return;
          }
        }
  
        localStorage.setItem(
          "debugState",
          JSON.stringify({
            slide: object.slide,
            state,
          })
        );
        setSlide(object.slide);
      },
    };
  
    if (state.name === "" || state.name === undefined) {
      return (
        <SignUp state={state} control={control} slide={where["assessment"]} />
      );
    }
  
    if (state.assessment === 0) {
      return (
        <Assessment state={state} control={control} slide={where["assessment"]} />
      );
    }
    
    if (displayTemplate ==="visitContainer"){
      
      if (!state.containers.length && !state.containerAudio.length && !state.containerAudioEntries.length) {
        return (
          <Desc state={state} control={control} slide={where["containerIntro"]} />
        );
      }
    }

    if (displayTemplate === "wetChoose") {

      if (state.bdiAssessment===0) {
        return (
          <Assessment
            state={state}
            control={control}
            slide={where["wetAssessment"]}
          />
        );
      }
      if (!state.consent) {
        return (
          <AudioPlay
            state={state}
            control={control}
            slide={where["historyIntro"]}
          />
        );
      }


      if (slide) {
        return (
          <MultiButton
            state={state}
            control={control}
            slide={where["wet_list"]}
          />
        );
      }

}
  
    if (displayTemplate === "emdrChoose") {
      if (!state.consent) {
        return (
          <AudioPlay
            state={state}
            control={control}
            slide={where["historyIntro"]}
          />
        );
        
      }
  
      if (!state.entries.length && !state.audio.length && !state.audioEntries.length) {
        return (
          <DetermineIfFirstTime
            state={state}
            control={control}
            goFinish="emdrChoose"
            where={where}
          />
        );
      }
  
      if (!state.containers.length && !state.containerAudio.length && !state.containerAudioEntries.length) {
        return (
          <Desc state={state} control={control} slide={where["containerIntro"]} />
        );
      }
  
      if (!state.emdrSessions.length) {
        return (
          <TrumaCreator
            state={state}
            control={control}
            slide={where["traumaCreator"]}
          />
        );
      }
      if (state.emdrSessions.length === 1) {
   
        return (
          <EMDRList
            state={state}
            control={control}
            slide={where["emdr_list"]}
          />
        );
      }
  
      if (state.emdrSessions.length > 1 && !state.EMDRSession) {
        return (
          <EMDRSelector
            state={state}
            control={control}
            slide={where["emdr_select"]}
          />
        );
      }
  
      if (state.EMDRSession) {
        return (
          <MultiButton
            state={state}
            control={control}
            slide={where["emdr_list"]}
          />
        );
      }
    }
  
    if (displayTemplate === "YesNo") {
      return <YesNo state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "yesNo") {
      return <YesNo state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeContainer") {
      return <WriteContainer state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeDown") {
      return <WriteEntry state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "recordAudioEmdr") {
      return <RecordAudioEmdr state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "recordAudioContainer") {
      return <RecordAudioContainer state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "recordEmdr") {
      return <RecordEmdrAudio state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "replayEmdr") {
        return <ReplayEmdrAudio state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "emdrAudio"){
      return <VisitEmdrAudio state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "audioPlay") {
      return <AudioPlay state={state} control={control} slide={slide} key={slide?.audio} />;
    } else if (displayTemplate === "desc") {
      return <Desc state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "visitCalmPlace") {
      return <VisitCalmPlace state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "visitSafeSpace") {
      return (
        <DetermineIfFirstTime state={state} control={control} slide={slide} where={where}/>
      );
    } else if (displayTemplate === "multiSelect") {
      return <MultiSelect state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "multiButton") {
      return <MultiButton state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "positiveBelief") {
      return <PositiveBelief state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "processTrauma") {
      return <ProcessTrauma state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "processMemory") {
      return <ProcessMemory state={state} control={control} slide={slide} />;
    } else if (displayTemplate==='endSession'){
      
      return <EndSession state={state} control={control} slide={slide} />;
    } else if (displayTemplate==='emdrList'){
      return <EMDRList state={state} control={control} slide={slide} />;
      
    } else if (displayTemplate === "selectMood") {
      return <SelectMood state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "setCalm") {
      return <SetCalm state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "setSUDS") {
      return <SetSUDS state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "homePage") {
      return <HomePage state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "multiAudio") {
      return <MultiAudio state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "journalEntries") {
      return <JournalEntries state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "writeWet") {
        return <WriteWet state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "assessment") {
      return <Assessment state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "bdiAssessment") {
      return <BDIAssessment state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientHistory") {
      return <PatientHistory state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "consetForm") {
      return <Consent state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "counter") {
      return <Counter state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "video") {
      return <Video state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "scale") {
      return <ScaleSlide state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "traumaCreator") {
      return <TrumaCreator state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "addJournal") {
      return <EMDRJournal state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "testTemplate") {
      return <TestTemplate state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "journalEntriesEMDR") {
      return <EMDRJournal state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientDES") {
      return <PatientDES state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "patientChecklist") {
      return <PatientChecklist state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "visitContainer") {
      return <VisitContainer state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "signUp") {
      return <SignUp state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "therapistHome") {
      return <TherapistHomepage state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "dashboard") {
      return <DashboardButtons state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "EMDRVideo"){
      return <EMDRVideo state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "testing"){
      return <Testing state={state} control={control} slide={slide} />;
    } else if (displayTemplate === "dropDown"){
      return <DropdownAudio state={state} control={control} slide={slide} key={slide?.audio} />;
    } else if (displayTemplate === "reviewJournals"){
      return <ReviewEmdrJournal state={state} control={control} slide={slide} />;
  
    }
  }



  export default InnerApp;