import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import {
  Form,
  Row,
} from "react-bootstrap";

import { useState } from "react";
import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";

const MultiButton = ({ state, control, slide }) => {
  const [answer, setAnswer] = useState(undefined);

  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  const changeSlide = async (index) => {

    if (index === undefined){
      await addJSON({
        variables: {
          json: JSON.stringify({
            answer: { answer },
            startTime,
            endTime: new Date().toISOString(),
            type: slide?.type,
            slide: slide?._slide,
          }),
        },
      });
    } else {
      await addJSON({
        variables: {
          json: JSON.stringify({
            answer: { answer:index },
            startTime,
            endTime: new Date().toISOString(),
            type: slide?.type,
            slide: slide?._slide,
          }),
        },
      });
    }
   
  };

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box">
          <h3 className="text-center mb-4">{slide.title}</h3>
          <p>{slide.description}</p>
          <br />

          {slide.buttons.map((button, index) => {
            return (
              <div
                key={"button:" + index}
                onClick={async(e) => {
                  await changeSlide(index);
                  control.goSlide(button);
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                {button.title}
              </div>
            );
          })}

          {slide.writeYourOwn ? (
            <>
              <br />
              <Form className="text-left">
                <Form.Group controlId="">
                  <Form.Label>Write your own:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write Your Own"
                    onChange={(e) => {
                      setAnswer(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>

              <div
                onClick={async() => {
                  await changeSlide();
                  control.setCustomAnswer(answer);
                  control.goSlide(slide.writeYourOwn);
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                {slide.writeYourOwn.title}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
};
export default MultiButton;
