import Navbar from "../Components/Navbar";
import React from "react";
import Countdown from "react-countdown";
import Sidepanel from "../Savyn/Sidepanel";

import { Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const Completionist = () => <>Next</>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <>{seconds === 0 ? 60 : seconds}</>;
  }
};

const Desc = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center my-4">{slide.title}</h3>
          <br />
          <p className="mb-4">{slide.description}</p>

          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={async() => {
              await addJSON({
                variables: {
                  json: JSON.stringify({
                    startTime,
                    endTime: new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  }),
                },
              });


              control.goSlide(slide.complete);
            }}
          >
            <Countdown date={Date.now() + slide.time} renderer={renderer} />
          </button>
        </div>
      </Row>
    </>
  );
};

export default Desc;
