
import Navbar from '../Components/Navbar';
import Sidepanel from "../Savyn/Sidepanel";
import {
  Row,
} from "react-bootstrap";


import { useState } from "react";

const VisitContainer = ({ state, control, slide }) => {


  const [selectContainer, setSelectContainer] = useState(undefined);
  const [selectContainerAudio, setSelectContainerAudio] = useState(undefined);
  const [selectContainerAudioEntry, setSelectContainerAudioEntry] = useState(undefined);


  if  (!state.containers){
    state.containers = [];
  }

  if  (!state.containerAudioEntries){
    state.containerAudioEntries = [];
  }

  if  (!state.containerAudio){
    state.containerAudio = [];
  }

  if (selectContainer !== undefined){


    return <>
      <Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" >
     

        <h3 className="text-center my-4">Container</h3><br/>

        <textarea onChange={(e)=>{
          
        }} className="form-control border border-secondary mt-n3" name="" rows="12" value={state.containers[selectContainer].entry} readOnly={true}></textarea>
           
        <button
          type="button"
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
          onClick={()=>{
            control.goWhere("howDidItFeel")
            setSelectContainer(undefined);
          }}
        >
        {state.savePosition?"Next":"Next"}
        </button>
       
</div>
</Row>

      {/* </div>
    </div> */}
  </>

  }

  if (selectContainerAudio !== undefined){

    let audioURL = state.containerAudio[selectContainerAudio];
    return <>
    <Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" style={{width:"800px"}}>
     
        <h3 className="mb-4">Container Audio Entry</h3>
        <p className="mb-4"></p>



        <div className="m-5">
          <audio controls>
          <source src={audioURL} type="audio/mpeg"/>

            Your browser does not support the audio tag.
                    </audio>
        </div>

        <button
          type="button"
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
        
          onClick={() => {
            control.goWhere("howDidItFeel")
            setSelectContainerAudio(undefined);
            setSelectContainer(undefined);
            setSelectContainerAudioEntry(undefined);
          }}
        >

        {state.savePosition?"Next":"Next"}
          
        </button>
        </div>
        </Row>
  
      {/* </div>
      </div> */}





  </>

  }

  if (selectContainerAudioEntry !== undefined){

    let audioURL = state.containerAudioEntries[selectContainerAudioEntry].audio;
    console.log("Test Audio Entry: ")
    console.log(audioURL);

    return <>
    <Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" style={{width:"800px"}}>
     
        <h3 className="mb-4">Container Audio Entry</h3>
        <p className="mb-4"></p>

        <div className="m-5">
          <audio controls>
            <source src={audioURL} type="audio/mpeg"/>
            Your browser does not support the audio tag.
          </audio>
        </div>

        <button
          type="button"
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
        
          onClick={() => {
            control.goWhere("howDidItFeel")
            setSelectContainerAudio(undefined);
            setSelectContainer(undefined);
            setSelectContainerAudioEntry(undefined);
          }}
        >

        {state.savePosition?"Next":"Next"}
          
        </button>
        </div>
        </Row>
  
      {/* </div>
      </div> */}

  </>

 }



  return (
    <>

<Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" >

          <h3>Container  Library</h3><br />

          
    

            {
              state.containerAudio.map((e, index)=>{
                return <div key={"containerAudio"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{

                  setSelectContainerAudio(index);
                  setSelectContainer(undefined);
                  setSelectContainerAudioEntry(undefined);
                }}>


                <i className="fas fa-microphone mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                {e.title}
                {/*Container recording {index+1}*/}
              </div>
              })
            }

            {
              state.containerAudioEntries.map((e, index)=>{
                return <div key={"containerAudioEntries"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{

                  setSelectContainerAudio(undefined);
                  setSelectContainer(undefined);
                  setSelectContainerAudioEntry(index);
                }}>

                <i className="fas fa-microphone mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                {e.title}
                {/*Container recording {index+1}*/}
              </div>
              })
            }

            {
              state.containers.map((e, index)=>{
                return <div key={"entries"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{
                  setSelectContainer(index);
                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                {e.title}
              </div>
              })
            }

          <button 
          type="submit" 
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
          onClick = {()=>{
            control.goWhere("createContainer");
            }}
          >
            Add a Container
            </button>



            


         

</div>
</Row>
        {/* </div>
      </div> */}







    </>
  );
};
export default VisitContainer;
