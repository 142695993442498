import React, { useRef, useEffect, useState } from "react";
const Completionist = () => <>Next</>;

const Canvas = ({ count, width, height, amplitude }) => {
  const canvasRef = useRef(null);
  

  
  const draw = (ctx) => {
    let x = Math.sin(count / (60/amplitude)) * ((width / 2) - 120);
    ctx.clearRect(0, 0, 3000, 3000);
    ctx.fillStyle = "#ffd804";
    ctx.strokeStyle = "2a8cff";
    ctx.beginPath();
    ctx.arc((x-(0)) + ((width / 2)), height / 2 - 40, width/30, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    //Our draw come here
    draw(context);
  }, [draw]);

  return (
    <canvas
      width={width}
      height={height - 80}
      ref={canvasRef}
    />
  );
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function AudioButton ({seconds,amplitude, startCount, stopTime}){

    const [audio, setAudio] = useState(undefined);
    const [playing, setPlaying] = useState(false);


    useEffect(()=>{

        if (audio){
            audio.p.pan.value =(Math.sin(seconds / (60/amplitude)))  
            //audio.p.pan.value=-1;
        }
        
    }

    );


    let countDown = Math.floor((stopTime-Date.now())/60);

    if (audio&&stopTime&&countDown<=0){
      startCount()
      setPlaying(false);
      if (audio){
          audio.o.stop();
      }
      
      setAudio(undefined);
    }

    return <button onClick={()=>{
      startCount()
        if (!playing){
            
            if (!audio){
                var audioContext = new AudioContext()
                var o = audioContext.createOscillator()
                var g = audioContext.createGain()
                let p = audioContext.createStereoPanner();
                
                o.connect(g)
                g.connect(p);
                p.connect(audioContext.destination);
                //g.connect(audioContext.destination)
                
        
                o.frequency.value = 528;
                g.gain.value =0.25
                //o.frequency.exponentialRampToValueAtTime(300, audioContext.currentTime + 2);
        
                o.start();
                setAudio({
                    o,
                    g,
                    p,
                    audioContext
                });
                setPlaying(true);
                return;
            }

            setPlaying(true);
        }else {
            setPlaying(false);
            if (audio){
                audio.o.stop();
            }
            
            setAudio(undefined);
        }
        
    }}>{playing?countDown:"Start"}</button>
}

function D3({}) {
  const [seconds, setSeconds] = useState(0);
  const [width, setWidth] = useState(window.width);
  const [height, setHeight] = useState(window.height);
  const [amplitude, setAmplitude] = useState(1);
  const [inputText, setInputText] = useState("");

  const [stopTime, setStopTime] = useState(undefined);

  

  useEffect(() => {
    function handleResize() {
      const wSize = getWindowDimensions();

      setWidth(wSize.width*.66);
      setHeight(wSize.height);
    }


    

    

    handleResize();

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000 / 60);
    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const startCount = ()=>{
    if (stopTime){
      setStopTime(undefined);
    }else{
      setStopTime(Date.now() + 3600*5)
    }
   
  }



  return (
    <>
      <Canvas
        count={stopTime?seconds:0}
        width={width}
        height={height}
        amplitude={amplitude}
      />
      <div style={{ marginLeft: "20px" }}>

        <AudioButton seconds={seconds} amplitude={amplitude} startCount={startCount} stopTime={stopTime}/>
      </div>
    </>
  );
}

/*

      <div>{seconds}</div>
        <span style={{ marginRight: "20px" }}>Amplitude:</span>
        <input
          value={inputText}
          onChange={(e) => {
            let value = parseFloat(e.target.value);
            if (value) {
              setAmplitude(value);
            } else {
                //setAmplitude(30);
            }
            setInputText(e.target.value);
          }}
        />
        */
export default D3;
