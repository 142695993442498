import withoutShowdow from "../Images/without_shadow.png";

import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import "@fontsource/open-sans";

import { useMutation } from "@apollo/react-hooks";
import gqlQuery from "../GQL";

const _Navbar = ({ state, control, slide }) => {
  const [setSession] = useMutation(gqlQuery.setState);

  return (
    <Navbar bg="white" expand="false" className="d-lg-none">
      <Navbar.Brand href="/home">
        <img
          alt=""
          src={withoutShowdow}
          width="40"
          height="40"
          className="d-inline-block align-top"
        />{" "}
        <h1 style={{ fontSize: "30px" }} className="d-inline-block align-top">
          SAVYN
        </h1>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" className=" " />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            onClick={() => {
              control.goWhere("homePage");
            }}
          >
            Home
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("breathExercise");
            }}
          >
            Breathing Exercises
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("breathExercise");
            }}
          >
            PTSD & Triggers
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("visitSafeSpace");
            }}
          >
            Calm Place
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("visitContainer");
            }}
          >
            Container
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("journalEntries");
            }}
          >
            Written Exposure Therapy
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              control.goWhere("emdrChoose");
            }}
          >
            EMDR
          </Nav.Link>
          <NavDropdown.Divider />
          <Nav.Link
            onClick={async() => {

              console.log(state);
              await setSession({
                variables: {
                  json: JSON.stringify(state),
                },
              });

              control.clearState();
              control.goWhere("homePage");
            }}
          >
            Sign out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default _Navbar;
