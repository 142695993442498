const ConvertText = (string, state)=>{
    let stringsArray = string.split("###")
  
    for (let index = 1, length = stringsArray.length; index<length;index=index+2){
            stringsArray[index] = state[stringsArray[index]];  
    }
  
    return stringsArray.join("")
  }


  export default ConvertText;