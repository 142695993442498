import Navbar from "../Components/Navbar";
import Sidebar from "../Savyn/Sidebar";
import Sidepanel from "../Savyn/Sidepanel";
import "@fontsource/open-sans";
import {
  Container,
  Form,
  Col,
  Row,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import ConvertText from '../Helper/ConvertText'


const Homepage = ({ state, control, slide }) => {

  return (
    <>
          <Navbar state={state} control={control} slide={slide}/>
      
       <Row className="bg-img min-vh-100" >
    
    
        <Sidepanel state={state} control={control} slide={slide}/>
          <div className="box">
          <h1 className="mb-5 text-left">{ConvertText(slide.title, state?.name)}</h1>
          {slide.buttons.map((button,index)=>{

            // if (button.meta==="wet"){
                        
            //   if (!state.bdiAssessment===0 && state.consent){
            //     control.goSlide("homePage");
            //     return ;
            //   }
            // }
// Display calm place and container on home page once set up from within emdr 
            // if (button.meta==="calmSpace"){
            
            //   if (!state.entries.length && !state.audio.length){
            //     return <></>
            //   }
            // }
            

            // if (button.meta==='container'){
            //   if (!state.containers.length){
            //     return <></>
            //   }
            // }



              return <div key={"Button"+index} onClick={
                ()=>{
                  if (button.meta==="container"){
                    if (state.containers.length>0){
                      control.goSlide(button.containerList);
                      return;
                    }

                  }
                  control.goSlide(button)
                }
            }
              //className="btn btn-block border text-center btn-lg "
              style = {{borderColor:"#006fde", fontSize:"14px", maxWidth:'400px', margin:'auto', borderRadius:"30px"}}
              className=" mb-3 btn  btn-block text-center btn-lg "
            >
              {button.title}
            </div>


            })}
         
<ul className="list-group text-left mt-3">

  <li className="list-group"><a href="https://www.ontario.ca/page/find-mental-health-support">Get Support</a></li>
  <li className="list-group"><a href="#">Connect with community</a></li>
</ul>
        </div>
        {/* </div> */}
        {/* <Col></Col> */}
              {/* </Container> */}
        </Row>

    </>
  );
};

export default Homepage;
