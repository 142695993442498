import {
  Button,
  Form,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const EditPage = ({ slide, show, upDate, handleClose, where, setWhere }) => {
  const aSlide = where[slide];

  let type = aSlide ? aSlide.type : undefined;

  let noEdit = false;

  let title = false;
  let description = false;
  let complete = false;
  let yesAndNo = false;
  let multi = false;
  let audio = false;
  let audioMulti = false;
  let scale = false;
  let video = false;
  let time = false;
  let save = false;
  let quit = false;

  if (type === "desc") {
    title = true;
    description = true;
    complete = true;
    noEdit = true;
  } else if (type === "multiAudio") {
    title = true;
    description = true;
    audioMulti = true;
    noEdit = true;
  } else if (type === "multiButton") {
    title = true;
    description = true;
    multi = true;
    noEdit = true;
  } else if (type === "multiSelect") {
    title = true;
    description = true;
    multi = true;
    noEdit = true;
  } else if (type === "audioPlay") {
    title = true;
    description = true;
    audio = true;
    complete = true;
    noEdit = true;
  } else if (type === "yesNo") {
    title = true;
    description = true;
    yesAndNo = true;
    noEdit = true;
  } else if (type === "scale") {
    title = true;
    description = true;
    complete = true;
    scale = true;
    noEdit = true;
  } else if (type === "video") {
    title = true;
    description = true;
    video = true;
    complete = true;
    noEdit = true;
  } else if (type === "counter") {
    title = true;
    description = true;
    time = true;
    complete = true;
    noEdit = true;
  } else if (type === "writeDown") {
    title = true;
    description = true;
    save = true;
    quit = true;
    noEdit = true;
  } else if (type === "scale") {
    title = true;
    description = true;
    noEdit = true;
  } else if (
    type === "emdrChoose" ||
    type === "assessment" ||
    type === "patientDES" ||
    type === "patientHistory" ||
    type === "patientChecklist" ||
    type === "consetForm" ||
    type === "traumaCreator" ||
    type === "emdr_select" ||
    type === "homePage" ||
    type === "visitContainer" ||
    type === "visitSafeSpace"
  ) {
  }

  if (!noEdit) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>You Can Not Edit This Slide</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  }

  if (noEdit) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Page</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InputGroup className="mb-3">
              {title ? (
                <>
                  <label htmlFor="basic-url">Title</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="title"
                    aria-label="title"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].title}
                    onChange={(e) => {
                      where[slide].title = e.target.value;
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {description ? (
                <>
                  <label htmlFor="basic-url">Description</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].description}
                    onChange={(e) => {
                      where[slide].description = e.target.value;
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {audio ? (
                <>
                  <label htmlFor="basic-url">Audio</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].audio}
                    onChange={(e) => {
                      where[slide].audio = e.target.value;
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {video ? (
                <>
                  <label htmlFor="basic-url">Video</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].video}
                    onChange={(e) => {
                      where[slide].video = e.target.value;
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {time ? (
                <>
                  <label htmlFor="basic-url">Counter Time</label>

                  <input
                    type="number"
                    value={where[slide].time}
                    onChange={(e) => {
                      where[slide].time = parseInt(e.target.value);
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {scale ? (
                <>
                  <label htmlFor="basic-url">Low</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].low}
                    onChange={(e) => {
                      where[slide].low = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <label htmlFor="basic-url">High</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].high}
                    onChange={(e) => {
                      where[slide].high = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <label htmlFor="basic-url">Min</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].min}
                    onChange={(e) => {
                      where[slide].min = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <label htmlFor="basic-url">Max</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].max}
                    onChange={(e) => {
                      where[slide].max = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <label htmlFor="basic-url">Step</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].step}
                    onChange={(e) => {
                      where[slide].step = e.target.value;
                      setWhere({ ...where });
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              {complete ? (
                <div>
                  <label htmlFor="basic-url">Complete Button</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].complete.title}
                    onChange={(e) => {
                      where[slide].complete.title = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      where[slide].complete.slide = e.target.value;
                      setWhere({ ...where });
                    }}
                  >
                    {Object.keys(where).map((e, index) => {
                      if (e === "noPage") {
                        return <></>;
                      }
                      return (
                        <option
                          key={"complete###" + index}
                          selected={e === where[slide].complete.slide}
                        >
                          {e}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              ) : (
                <></>
              )}

              {save ? (
                <div>
                  <label htmlFor="basic-url">Save Button</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].save.title}
                    onChange={(e) => {
                      where[slide].save.title = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      where[slide].save.slide = e.target.value;
                      setWhere({ ...where });
                    }}
                  >
                    {Object.keys(where).map((e, index) => {
                      if (e === "noPage") {
                        return <></>;
                      }
                      return (
                        <option
                          key={"save###" + index}
                          selected={e === where[slide].save.slide}
                        >
                          {e}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              ) : (
                <></>
              )}

              {quit ? (
                <div>
                  <label htmlFor="basic-url">Quit Button</label>
                  <FormControl
                    style={{ width: "500px" }}
                    placeholder="description"
                    aria-label="description"
                    aria-describedby="basic-addon2"
                    defaultValue={where[slide].quit.title}
                    onChange={(e) => {
                      where[slide].quit.title = e.target.value;
                      setWhere({ ...where });
                    }}
                  />

                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      where[slide].quit.slide = e.target.value;
                      setWhere({ ...where });
                    }}
                  >
                    {Object.keys(where).map((e, index) => {
                      if (e === "noPage") {
                        return <></>;
                      }
                      return (
                        <option
                          key={"quit###" + index}
                          selected={e === where[slide].quit.slide}
                        >
                          {e}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              ) : (
                <></>
              )}

              {yesAndNo ? (
                <>
                  <div style={{ width: "100%" }}>
                    <label htmlFor="basic-url">Yes</label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        where[slide].yes.slide = e.target.value;
                        setWhere({ ...where });
                      }}
                    >
                      {Object.keys(where).map((e, index) => {
                        if (e === "noPage") {
                          return <></>;
                        }
                        return <option key={"YES" + index}>{e}</option>;
                      })}
                    </Form.Control>
                  </div>
                  <div>
                    <label htmlFor="basic-url">No</label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        where[slide].no.slide = e.target.value;
                        setWhere({ ...where });
                      }}
                    >
                      {Object.keys(where).map((e, index) => {
                        if (e === "noPage") {
                          return <></>;
                        }
                        return <option key={"NO" + index}>{e}</option>;
                      })}
                    </Form.Control>
                  </div>
                </>
              ) : (
                <></>
              )}
              {audioMulti ? (
                <>
                  {where[slide].audio.map((e, index) => {
                    return (
                      <div
                        key={"multiAudio" + index}
                        style={{ paddingTop: "20px" }}
                      >
                        <label htmlFor="basic-url">Audio {index}</label>
                        <FormControl
                          style={{ width: "500px" }}
                          placeholder="description"
                          aria-label="description"
                          aria-describedby="basic-addon2"
                          defaultValue={where[slide].audio[index].title}
                          onChange={(e) => {
                            where[slide].audio[index].title = e.target.value;
                            setWhere({ ...where });
                          }}
                        />

                        <FormControl
                          style={{ width: "500px" }}
                          placeholder="description"
                          aria-label="description"
                          aria-describedby="basic-addon2"
                          defaultValue={where[slide].audio[index].audio}
                          onChange={(e) => {
                            where[slide].audio[index].audio = e.target.value;
                            setWhere({ ...where });
                          }}
                        />
                        <Button onClick={()=>{
                          delete where[slide].audio[index];
                          setWhere({ ...where });
                        }}> Remove </Button>
                      </div>
                    );
                  })}
                  
                                          <Button style={{marginTop:"20px"}} onClick={()=>{
                          where[slide].audio.push({
                            
                              title: "New Audio",
                              audio:
                                "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
                            
                          });
                          setWhere({ ...where });
                        }}> New Audio </Button>
                </>
              ) : (
                <></>
              )}

              {multi ? (
                <>
                  {where[slide].buttons.map((e, index) => {
                    return (
                      <div
                        key={"multiButton" + index}
                        style={{ paddingTop: "20px" }}
                      >
                        <label htmlFor="basic-url">Button {index}</label>
                        <FormControl
                          style={{ width: "500px" }}
                          placeholder="description"
                          aria-label="description"
                          aria-describedby="basic-addon2"
                          defaultValue={where[slide].buttons[index].title}
                          onChange={(e) => {
                            where[slide].buttons[index].title = e.target.value;
                            setWhere({ ...where });
                          }}
                        />

                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            where[slide].buttons[index].slide = e.target.value;
                            setWhere({ ...where });
                          }}
                        >
                          {Object.keys(where).map((e, index2) => {
                            if (e === "noPage") {
                              return <></>;
                            }
                            return (
                              <option
                                key={"mutlti" + index + "XXX" + index2}
                                selected={
                                  e === where[slide].buttons[index].slide
                                }
                              >
                                {e}
                              </option>
                            );
                          })}
                        </Form.Control>

                        <Button onClick={()=>{
                          delete where[slide].buttons[index];
                          setWhere({ ...where });
                        }}> Remove </Button>
                      </div>
                    );
                  })}
<Form.Group controlId="formBasicCheckbox">
<Button style={{marginTop:"20px", marginBottom:"20px"}} onClick={()=>{
                          where[slide].buttons.push(      {
                            title: "Home Page",
                            slide: "homePage",
                          },);
                          setWhere({ ...where });
                        }}> New Button </Button>
</Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Write You Own"
                      onChange={(e) => {
                        if (e.target.checked) {
                          where[slide].writeYourOwn = {
                            title: "New Button",
                            description: "Descrption",
                            slide: "homePage",
                          };
                          setWhere({ ...where });
                        } else {
                          delete where[slide].writeYourOwn;
                          setWhere({ ...where });
                        }
                      }}
                    />
                  </Form.Group>

                  {where[slide].writeYourOwn ? (
                    <div style={{ marginTop: "40px" }}>
                      <label htmlFor="basic-url">Write Your Own Button</label>
                      <FormControl
                        style={{ width: "500px" }}
                        placeholder="description"
                        aria-label="description"
                        aria-describedby="basic-addon2"
                        defaultValue={where[slide].writeYourOwn.title}
                        onChange={(e) => {
                          where[slide].writeYourOwn.title = e.target.value;
                          setWhere({ ...where });
                        }}
                      />
                      <label htmlFor="basic-url">
                        Write Your description above the Button
                      </label>
                      <FormControl
                        style={{ width: "500px" }}
                        placeholder="description"
                        aria-label="description"
                        aria-describedby="basic-addon2"
                        defaultValue={where[slide].writeYourOwn.description}
                        onChange={(e) => {
                          where[slide].description.title = e.target.value;
                          setWhere({ ...where });
                        }}
                      />

                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          where[slide].writeYourOwn.slide = e.target.value;
                          setWhere({ ...where });
                        }}
                      >
                        {Object.keys(where).map((e, index) => {
                          if (e === "noPage") {
                            return <></>;
                          }
                          return (
                            <option
                              key={"writeYourOwn###" + index}
                              selected={e === where[slide].writeYourOwn.slide}
                            >
                              {e}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  }

  if (type === "desc") {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Page</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                style={{ width: "500px" }}
                placeholder="title"
                aria-label="title"
                aria-describedby="basic-addon2"
                defaultValue={where[slide].title}
                onChange={(e) => {
                  where[slide].title = e.target.value;
                  setWhere({ ...where });
                }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                style={{ width: "500px" }}
                placeholder="description"
                aria-label="description"
                aria-describedby="basic-addon2"
                defaultValue={where[slide].description}
                onChange={(e) => {
                  where[slide].description = e.target.value;
                  setWhere({ ...where });
                }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                style={{ width: "500px" }}
                placeholder="description"
                aria-label="description"
                aria-describedby="basic-addon2"
                defaultValue={where[slide].complete.title}
                onChange={(e) => {
                  where[slide].complete.title = e.target.value;
                  setWhere({ ...where });
                }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Body>
            <Form.Control
              as="select"
              onChange={(e) => {
                where[slide].complete.slide = e.target.value;
                setWhere({ ...where });
              }}
            >
              {Object.keys(where).map((e, index) => {
                if (e === "noPage") {
                  return <></>;
                }
                return <option
                key={"descn###" + index}
                >{e}</option>;
              })}
            </Form.Control>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  } else if (type === "yesNo") {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Page</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                style={{ width: "500px" }}
                placeholder="title"
                aria-label="title"
                aria-describedby="basic-addon2"
                defaultValue={where[slide].title}
                onChange={(e) => {
                  where[slide].title = e.target.value;
                  setWhere({ ...where });
                }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                style={{ width: "500px" }}
                placeholder="description"
                aria-label="description"
                aria-describedby="basic-addon2"
                defaultValue={where[slide].description}
                onChange={(e) => {
                  where[slide].description = e.target.value;
                  setWhere({ ...where });
                }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Body>
            <Form.Control
              as="select"
              onChange={(e) => {
                where[slide].yes.slide = e.target.value;
                setWhere({ ...where });
              }}
            >
              {Object.keys(where).map((e, index) => {
                if (e === "noPage") {
                  return <></>;
                }
                return <option
                key={"yesAndNo###" + index}
                >{e}</option>;
              })}
            </Form.Control>
          </Modal.Body>

          <Modal.Body>
            <Form.Control
              as="select"
              onChange={(e) => {
                where[slide].no.slide = e.target.value;
                setWhere({ ...where });
              }}
            >
              {Object.keys(where).map((e, index) => {
                if (e === "noPage") {
                  return <></>;
                }
                return <option
                key={"noAndYes###" + index}
                >{e}</option>;
              })}
            </Form.Control>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  } else if (type === "selectMood") {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Page</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Control
              as="select"
              onChange={(e) => {
                where[slide].complete.slide = e.target.value;
                setWhere({ ...where });
              }}
            >
              {Object.keys(where).map((e, index) => {
                if (e === "noPage") {
                  return <></>;
                }
                return <option
                key={"selectMood###" + index}
                >{e}</option>;
              })}
            </Form.Control>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  } else if (type === "setSUDS") {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Edit Page</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Control
              as="select"
              onChange={(e) => {
                where[slide].complete.slide = e.target.value;
                setWhere({ ...where });
              }}
            >
              {Object.keys(where).map((e, index) => {
                if (e === "noPage") {
                  return <></>;
                }
                return <option
                key={"suds" + index}
                >{e}</option>;
              })}
            </Form.Control>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>You Can Not Edit This Slide</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
};

export default EditPage;
