let where = {
  emdr_select: {
    type: "emdr_select",
  },

  emdr_list: {
    type: "emdrList",
    title: "Please Choose Your EMDR Session",
    description: "",
    buttons: [
      {
        title: "Start Session",
        slide: "emdr_1",
      },
      {
        title: "Add EMDR journal",
        slide: "addJournal",
      },
      {
        title: "Review your EMDR Journal",
        slide: "reviewJournals",
      },
      {
        title: "Go Back",
        slide: "homePage",
      },
    ],
  },

  emdr_1: {
    type: "audioPlay",
    title: "Welcome ###name### to your EMDR session",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/3%20session%202%20intro%20script.mp3",
    complete: {
      title: "Start the Session",
      slide: "emdr_2",
    },
  },

  emdr_2: {
    type: "audioPlay",
    title: "Let's do some deep breathing",
    description:
      "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    audio:"https://storage.googleapis.com/savyn-public/New%20-%20Square%20Breathing.mp3",
    // type: "multiAudio",
    // title: "Let's do some deep breathing",
    // description:
    //   "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    // audio: [
    //   {
    //     title: "1 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "3 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "7 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    // ],
    complete: {
      title: "Continue",
      slide: "emdr_3",
    },
  },

  emdr_3: {
    type: "yesNo",
    title: "Did you want to visit your calm place?",
    yes: {
      slide: "visitCalmPlace",
      bookmarks: [
        {
          bookMark: "emdrReturn",
          slide: "emdr_4",
        },
      ],
    },
    no: {
      slide: "emdr_4",
    },
  },

  emdr_4: {
    type: "multiButton",
    title:
      "When you think of that incident today, what thoughts did you have about yourself in that moment.",
    description: "",
    buttons: [
      {
        title: "I don't deserve love",
        slide: "emdr_5",
      },
      {
        title: "I am worthless",
        slide: "emdr_5",
      },
      {
        title: "I should have done something",
        slide: "emdr_5",
      },
      {
        title: "I cannot protect myself",
        slide: "emdr_5",
      },
      {
        title: "I am not good enough",
        slide: "emdr_5",
      },
      {
        title: "I cannot trust anyone",
        slide: "emdr_5",
      },
    ],
    writeYourOwn: {
      title: "Next",
      slide: "emdr_5",
    },
  },

  emdr_5: {
    type: "yesNo",
    title: "How are you doing?",
    description:
      "In the next few screens, we will begin processing your traumatic memory. This process may be distressing for some. At any point, if you do not want to continue you may exit the session. Do you want to continue with the session?",
    yes: {
      slide: "emdr_6",
    },
    no: {
      slide: "homePage",
    },
  },

  emdr_6: {
    type: "audioPlay",
    title: "Let's do some deep breathing",
    description:
      "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    audio:"https://storage.googleapis.com/savyn-public/New%20-%20Square%20Breathing.mp3",
    // type: "multiAudio",
    // title: "Let's do some deep breathing",
    // description:
    //   "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    // audio: [
    //   {
    //     title: "1 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "3 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "7 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    // ],
    complete: {
      title: "Next",
      slide: "emdr_7",
    },
  },

  emdr_7: {
    type: "processTrauma",
    //title: "We will process your traumatic memory now",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/4%20processing%20traumatic%20memory.mp3",
    complete: {
      title: "Start the Session",
      slide: "emdr_8",
    },
  },

  emdr_8: {
    type: "counter",
    title: "Think of your traumatic memory",
    time: 30000,
    description: "",
    complete: {
      slide: "emdr_9",
    },
  },

  emdr_9: {
    type: "multiSelect",
    title: "What emotion comes up for you when you think about that incident?",
    description: "",
    buttons: [
      {
        title: "Angry",
        slide: "emdr_10",
      },
      {
        title: "Scared",
        slide: "emdr_10",
      },
      {
        title: "Anxious",
        slide: "emdr_10",
      },
      {
        title: "Sad",
        slide: "emdr_10",
      },
      {
        title: "Hurt",
        slide: "emdr_10",
      },
    ],
    writeYourOwn: {
      title: "Next",
      slide: "emdr_10",
    },
  },

  emdr_10: {
    type: "setSUDS",
    title:"How distressing is it today when you think about what happened at that time?",
    description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
    complete: {
      slide: "emdr_11",
    },
  },

  emdr_11: {
    type: "audioPlay",
    title: "Body Scan",
    description:
      "Listen to our instructions on how to scan your body and identify where you feel the stress.",
      audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/5%20body%20scan.mp3", 
    complete: {
      title: "Begin Body Scan",
      slide: "emdr_12",
    },
  },

  emdr_12: {
    type: "counter",
    title: "Start scanning your body and start your timer.",
    time: 15000,
    description: "",
    complete: {
      slide: "emdr_13",
    },
  },
  emdr_13: {
    type: "processMemory",
    //title:
      //"We will start doing the eye movement exercise now, listen to our instructions",
    description: "",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/6%20eye%20movement%20processesing.mp3",
    complete: {
      title: "Next",
      slide: "emdr_14",
    },
  },

  emdr_14: {
    type: "yesNo",
    title: "How are you doing?",
    description:
      "In the next few screens, we will begin the eye movement exercise. This process may be distressing for some. At any point if you do not want to continue, you may exit the session. Do you want to continue with the session?",
    yes: {
      slide: "emdr_15",
    },
    no: {
      slide: "homePage",
    },
  },

  emdr_15: {
    type: "video",
    title: "EMDR Eye Movement Exercise",
    description: "",
    video:
      "https://storage.googleapis.com/savyn-public/Left%20and%20Right.mp4",
    complete: {
      title: "Next",
      slide: "emdr_16",
    },
  },

  emdr_16: {
    type: "yesNo",
    title:
      "You may notice you are triggered. This is part of the process.",
    description: "We highly encourage you to continue to get a positive outcome. Would you like to continue?",
    yes: {
      slide: "emdr_17",
    },
    no: {
      slide: "homePage",
    },
  },

  // emdr_17a: {
  //   type: "yesNo",
  //   title: "Would you like to go to your calm place?",
  //   description: "",
  //   yes: {
  //     slide: "visitSafeSpace",
  //     bookmarks: [
  //       {
  //         bookMark: "emdrReturn",
  //         slide: "emdr_18",
  //       },
  //     ],
  //   },
  //   no: {
  //     slide: "emdr_18",
  //   },
  // },
  emdr_17: {
    type: "audioPlay",
    title: "What are you noticing now?",
    description:
      "There are many ways that you may have responded to the processing. Here are only a few of the ways that you may have responded. The picture you have of the incident may have changed. Rather than an image, you may have had a physical reaction, felt it in your body. Maybe it's an emotion. Notice what you're feeling now. Take a moment and think about it, did anything change for you?",
      //audio: "https://storage.googleapis.com/savyn-media/Session%201%20-%20End.mp3",
      audio:"https://storage.googleapis.com/savyn-public/EMDR%20Session%202%20What%20are%20you%20noticing%20now.mp3",
    complete: {
      title: "Next",
      slide: "emdr_18",
    },
  },
  emdr_18: {
    type: "recordEmdr",
    title:
      "What are you noticing now? You can record what you felt during the session and replay it.",
    quit: {
      slide: "emdr_20",
    },
    save: {
      slide: "emdr_19",
    },
  },
  emdr_19: {
    type: "replayEmdr",
    title:
      "Replay what you felt during the session.",
    complete: {
      title:"Next",
      slide: "emdr_20",
    },
  },
  emdr_20: {
    type: "yesNo",
    title: "Have you noticed a change?",
    description: "",
    yes: {
      slide: "emdr_23a",
    },
    no: {
      slide: "emdr_21",
    },
  },

  emdr_21: {
    type: "yesNo",
    title: "Would you like to do another set of eye movement processing?",
    description: "",
    yes: {
      slide: "emdr_13",
    },
    no: {
      slide: "emdr_22",
    },
  },
  emdr_22: {
    type: "audioPlay",
    title: "Is there anything else that you may not have considered?",
    description:
      "It may seem like nothing has changed now, but when you come back next time, consider how you feel, your emotions, your body sensations. Also consider, there may be some elements of the incident that you may have not considered for processing. Observe it from a distance and notice what you see",
    audio:
      "https://storage.googleapis.com/savyn-media/Session%201%20-%20End.mp3",
    complete: {
      title: "Next",
      slide: "emdr_23b",
    },
  },
  emdr_23a: {
    type: "setSUDS",
    title:"How distressing is it today when you think about what happened at that time?",
    description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
    complete: {
      slide: "emdr_24",
    },
  },
  emdr_23b: {
    type: "setSUDS",
    title:"How distressing is it today when you think about what happened at that time?",
    description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
    complete: {
      slide: "emdr_25",
    },
  },

  emdr_24: {
    type: "yesNo",
    title: "Would you like to do another set of eye movement processing?",
    description: "",
    yes: {
      slide: "emdr_13",
    },
    no: {
      slide: "emdr_25",
    },
  },

  emdr_25: {
    type: "multiButton",
    title:
      "When you think of that incident today, what positive belief would you have liked to have thought about yourself in that moment?",
    description: "",
    buttons: [
      {
        title: "I did the best I could",
        slide: "emdr_26a",
      },
      {
        title: "I am not at fault",
        slide: "emdr_26b",
      },
      {
        title: "I am safe now",
        slide: "emdr_26c",
      },
      {
        title: "I am in control",
        slide: "emdr_26d",
      },
      {
        title: "I am important",
        slide: "emdr_26e",
      },
      {
        title: "I can get what I want",
        slide: "emdr_26f",
      },
    ],
    writeYourOwn: {
      title: "Next",
      description:"Write You Own, make them an I statement like above:",
      slide: "customPositiveBelief",
    },
  },
  customPositiveBelief: {
    type: "positiveBelief",
    title: "When you think about the incident how true is it in your life today?",
    description: "",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title: "Next",
      slide: "emdr_27"
    }
  },
  emdr_26: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I did the best I could, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26a: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I did the best I could, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26b: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I am not at fault, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26c: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I am safe now, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26d: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I am in control, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26e: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I am important, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },
  emdr_26f: {
    type: "scale",
    title:
      "When you think about the incident how true is it in your life today?",
    description:
      "When you think about the incident, how true is it in your life today? I can get what I want, on a scale of 1 to 7 where 1 feels completely false and 7 feels completely true?",
    low: "Completely False",
    high: "Completely True",
    min: 1,
    max: 7,
    step: 1,
    complete: {
      title:"Next",
      slide: "emdr_27",
    },
  },

  emdr_27: {
    type: "audioPlay",
    title: "Let's do some deep breathing",
    description:
      "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    audio:"https://storage.googleapis.com/savyn-public/New%20-%20Square%20Breathing.mp3",
    complete: {
      title: "Next",
      slide: "emdr_28",
    },
  },
  emdr_28: {
    type: "yesNo",
    title: "Did any other trauma come up?",
    description: "",
    yes: {
      slide: "traumaCreatorEmdr",
    },
    no: {
      slide: "emdr_29",
    },
  },

  emdr_29: {
    type: "audioPlay",
    title: "Session Wrap-Up",
    description: "",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/7%20session%202%20end%20script.mp3",
    complete: {
      title: "Next",
      slide: "emdr_30",
    },
  },
  emdr_30: {
    type: "audioPlay",
    title: "Closure",
    description:
      "We will take a few minutes in the next few slide to ground and find your center before you move on with the rest of day by showing how to use the idea of containers and visiting your calm place.",
      //audio: "https://storage.googleapis.com/savyn-media/Session%201%20-%20End.mp3",
      audio:"https://storage.googleapis.com/savyn-public/EMDR%20Session%202%20Closure.mp3",
    complete: {
      title: "Next",
      slide: "emdr_31",
    },
  },
  emdr_31: {
    type: "audioPlay",
    title:
      "Use container",
    description:
      "One at a time, think about the memories not in any great detail, just think of it, name it, put it into the container. Once you have placed all those memories into your container, put a lid on the container and close it. Imagine putting the container somewhere out of your direct view, knowing that it's there if you need it, but for now all those memories are safely put away inside your container.",
    audio: 
    //"https://storage.googleapis.com/savyn-media/Session%201%20-%20End.mp3",
      "https://storage.googleapis.com/savyn-public/EMDR%20Session%202%20Use%20your%20Container.mp3",
    complete: {
      title: "Next",
      slide:"emdr_31a",
    },
  },
  emdr_31a:{
    type:"audioPlay",
    title:"Grounding with a body scan",
    description:"Follow the instructions on how to calm your body by doing a body a scan before we close",
    audio:"https://storage.googleapis.com/savyn-public/BodyScan.mp3",
    complete:{
      title:"Next",
      slide:"visitCalmPlace",
      bookmarks:[
        {
          bookMark:"emdrReturn",
          slide:"emdr_32"
        }
      ]
    }

  },

  emdr_32:{
    type: "multiButton",
      title: "How did you feel using the calm place?",
      description: "",
      buttons: [
        {
          title: "Completely calm",
          slide: "emdr_33",
        },
        {
          title: "A little bit calm",
          slide: "emdr_33",
        },
        {
          title: "I need to work on it.",
          slide: "emdr_33",
        },
        {
          title: "Not calm.",
          slide: "emdr_33",
        },
      ],
  },

  emdr_33: {
    type: "selectMood",
    complete: {
      slide: "emdr_34",
    },
  },

  emdr_34: {
    type: "endSession",
    complete: {
      slide: "emdr_35",
    },
  },

  emdr_35: {
    type: "multiButton",
    title: "Remember To Journal",
    description:
      "If you feel triggered anytime during the week, you can journal.",
    buttons: [
      {
        title: "EMDR Journal",
        slide: "journalEntriesEMDR",
      },
      {
        title: "Exit",
        slide: "homePage",
      },
    ],
  },

  journalEntriesEMDR: {
    type: "journalEntriesEMDR",
    complete: {
      title: "Save Entry",
      slide: "homePage",
    },
  },

  emdrChoose: {
    type: "emdrChoose",
  },

  emdrProcessNewTrauma: {
    type: "yesNo",
    title: "",
    description: "We suggest that you continue with the first/ original trauma. Are you sure you want to process this newly discovered trauma?",
    yes: {
      slide: "",
    },
    no: {
      slide: "",
    },
  },

  wetChoose:{
    type:"wetChoose",
  },
  wetAssessment: {
    type: "assessment",
    endPoint: {
      title: "Screening Questionnaire",
      value: {
        0: {
          question:
            "Your assessment results are not indicative of symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. A score of 1 indicates you are managing your stress symptoms well.",
          answer: [
            {
              title: "Next",
              slide: "bdiAssessment",
            },
            
          ],
        },
        25: {
          question:
            "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. A score of 1 indicates you are managing your stress symptoms well.",
          answer: [
            {
              title: "Next",
              slide: "bdiAssessment",
            },
           
          ],
        },
        50: {
          question:
            "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. People who scored high on the assessment like you did can benefit from talking to a healthcare provider directly. Seeking help is very important if you think you may have other trauma-related problems that this assessment would not identify, such as depression, substance abuse or anxiety.",
          answer: [
            {
              title: "Contact your family physician or find local health care provider.",
              value: "https://www.ontario.ca/page/find-mental-health-support",
              method: "Jump",
            },
          ],
        },
      },
    },

    questions: [
      {
        title: "Screening Questionnaire",
        question:
          "On the following screens are some issues that people sometimes have as a response to encountering stressful life events. Please read each statement carefully, then select the option that best describes how much you have been bothered by each problem in the past month. Please select only one answer for each question.",
        answer: [
          {
            title: "Begin",
            value: 0,
          },
        ],
      },
      {
        question:
          "Have you had repeated, disturbing memories, thoughts, or images of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you had repeated, disturbing dreams of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Were you suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you felt very upset when something reminded you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Were you having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been avoiding thinking or talking about a stressful experience from the past or avoided having feelings related to it?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been avoiding activities or situations because they remind you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you had trouble remembering important parts of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Do you feel a loss of interest in things that you used to enjoy?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling distant or cut off from other people?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been feeling emotionally numb or being unable to have loving feelings for those close to you?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been feeling as if your future will somehow be cut short?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you had trouble falling or staying asleep?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling irritable or having angry outbursts?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Are you having difficulty concentrating?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Are you being “super alert” or watchful on guard?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling jumpy or easily startled?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
    ],
  },

  assessment: {
    type: "assessment",
    _slide: "assessment",
    endPoint: {
      title: "Screening Questionnaire",
      value: {
        0: {
          question:
            "On the following screens are some issues that people sometimes have as a response to encountering stressful life events. Please read each statement carefully, then select the option that best describes how much you have been bothered by each problem in the past month. Please select only one answer for each question.",
          answer: [
            {
              title: "Start breathing exercises",
              slide: "breathExercise",
            },
            {
              title: "Learn about PTSD & Triggers",
              slide: "breathExercise",
            },
            {
              title: "Create a calm place",
              slide: "haveSafeSpace",
            },
            {
              title: "Create a place to put away disturbing memories (container)",
              slide: "containerIntro",
            },
            {
              title: "Start desensitizing memories (EMDR)",
              slide: "emdrChoose",
            },

            {
              title: "I want to take the assessment again",
              slide: "assessment",
            },
          ],
        },
        25: {
          question:
            "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. A score of 1 indicates you are managing your stress symptoms well. You can reduce and manage some of these reactions by using the following Savyn features",
          answer: [
            {
              title: "Start breathing exercises",
              slide: "breathExercise",
            },
            {
              title: "Learn about PTSD & Triggers",
              slide: "breathExercise",
            },
            {
              title: "Create a calm place",
              slide: "haveSafeSpace",
            },
            {
              title: "Create a place to put away disturbing memories (container)",
              slide: "containerIntro",
            },
            {
              title: "Start desensitizing memories (EMDR)",
              slide: "emdrChoose",
            },

            {
              title: "I want to take the assessment again",
              slide: "assessment",
            },
          ],
        },
        50: {
          question:
            "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. People who scored high on the assessment like you did can benefit from talking to a healthcare provider directly. Seeking help is very important if you think you may have other trauma-related problems that this assessment would not identify, such as depression, substance abuse or anxiety.",
          answer: [
            {
              title: "Contact your family physician or find local health care provider.",
              value: "https://www.ontario.ca/page/find-mental-health-support",
              method: "Jump",
            },
          ],
        },
      },
    },

    questions: [
      {
        title: "Screening Questionnaire",
        question:
          "On the following screens are some issues that people sometimes have as a response to encountering stressful life events. Please read each statement carefully, then select the option that best describes how much you have been bothered by each problem in the past month. Please select only one answer for each question.",
        answer: [
          {
            title: "Begin",
            value: 0,
          },
        ],
      },
      {
        question:
          "Have you had repeated, disturbing memories, thoughts, or images of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you had repeated, disturbing dreams of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Were you suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you felt very upset when something reminded you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Were you having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been avoiding thinking or talking about a stressful experience from the past or avoided having feelings related to it?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been avoiding activities or situations because they remind you of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you had trouble remembering important parts of a stressful experience from the past?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Do you feel a loss of interest in things that you used to enjoy?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling distant or cut off from other people?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been feeling emotionally numb or being unable to have loving feelings for those close to you?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question:
          "Have you been feeling as if your future will somehow be cut short?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you had trouble falling or staying asleep?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling irritable or having angry outbursts?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Are you having difficulty concentrating?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Are you being “super alert” or watchful on guard?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
      {
        question: "Have you been feeling jumpy or easily startled?",
        answer: [
          {
            title: "Not at all",
            value: 1,
          },
          {
            title: "A little bit",
            value: 2,
          },
          {
            title: "Moderately",
            value: 3,
          },
          {
            title: "Quite a bit",
            value: 4,
          },
          {
            title: "Extremely",
            value: 5,
          },
        ],
      },
    ],
  },
  bdiAssessment: {
    type: "bdiAssessment",
    _slide:"bdiAssessment",
    endPoint: {
      title: "BDI Screening Questionnaire",
      value: {
        0: {
          question:
            "No symptoms of depression ",
          answer: [
            {
              title: "Next",
              slide: "historyDES",
            },
          ],
        },
        10: {
          question:
            "These ups and downs are considered normal.",
            answer: [
              {
                title: "Next",
                slide: "historyDES",
              },
            ],
        },
        16: {
          question:
            "Mild mood disturbance.",
          answer: [
            {
              title: "Next",
              slide: "historyDES",
            },
          ],
        },
        30: {
          question:
            "Moderate depression. ",
          answer: [
            {
              title: "Next",
              slide: "historyDES",
            },
            
          ],
        },
        40: {
          question:
            "Severe depression.",
          answer: [
            {
              title: "Next",
              slide: "breathExercise",
            },
            
          ],
        },
        63: {
          question:
            "Extreme depression. ",
          answer: [
                  {
                    title: "Find local health care provider",
                    value: "https://www.ontario.ca/page/find-mental-health-support",
                    method: "Jump",
                  }
            
          ],
        },
        // 50: {
        //   question:
        //     "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. People who scored high on the assessment like you did can benefit from talking to a healthcare provider directly. Seeking help is very important if you think you may have other trauma-related problems that this assessment would not identify, such as depression, substance abuse or anxiety.",
        //   answer: [
        //     {
        //       title: "Find local health care provider",
        //       value: "https://www.ontario.ca/page/find-mental-health-support",
        //       method: "Jump",
        //     },
        //   ],
        // },
      },
    },

    questions: [
      {
        title: "BDI Screening Questionnaire",
        question:
          "On the following screens are questions to assess your mood as a response to the issues you’ve faced. Please read each statement carefully, then select the option that best describes how you feel. Please select only one answer for each question.",
        answer: [
          {
            title: "Begin",
            value: 0,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I do not feel sad.",
            value: 0,
          },
          {
            title: "I feel sad.",
            value: 1,
          },
          {
            title: "I am sad all the time and I can't snap out of it. ",
            value: 2,
          },
          {
            title: "I am so sad and unhappy that I can't stand it.",
            value: 3,
          },
          
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I am not particularly discouraged about the future. ",
            value: 0,
          },
          {
            title: "I feel discouraged about the future.",
            value: 1,
          },
          {
            title: " I feel I have nothing to look forward to.",
            value: 2,
          },
          {
            title: "I feel the future is hopeless and that things cannot improve.",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I do not feel like a failure.",
            value: 0,
          },
          {
            title: "I feel I have failed more than the average person. ",
            value: 1,
          },
          {
            title: "As I look back on my life, all I can see is a lot of failures. ",
            value: 2,
          },
          {
            title: "I feel I am a complete failure as a person. ",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I get as much satisfaction out of things as I used to. ",
            value: 0,
          },
          {
            title: "I don't enjoy things the way I used to. ",
            value: 1,
          },
          {
            title: "I don't get real satisfaction out of anything anymore.",
            value: 2,
          },
          {
            title: "I am dissatisfied or bored with everything.",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I don't feel particularly guilty. ",
            value: 0,
          },
          {
            title: "I feel guilty a good part of the time.",
            value: 1,
          },
          {
            title: "I feel quite guilty most of the time.",
            value: 2,
          },
          {
            title: "I feel guilty all of the time.",
            value: 3,
          },
          
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I don't feel I am being punished.",
            value: 0,
          },
          {
            title: "I feel I may be punished. ",
            value: 1,
          },
          {
            title: "I expect to be punished.",
            value: 2,
          },
          {
            title: " I feel I am being punished. ",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I don't feel disappointed in myself.",
            value: 0,
          },
          {
            title: "I am disappointed in myself.",
            value: 1,
          },
          {
            title: "I am disgusted with myself.",
            value: 2,
          },
          {
            title: "I hate myself. ",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I don't feel I am any worse than anybody else.",
            value: 0,
          },
          {
            title: " I am critical of myself for my weaknesses or mistakes. ",
            value: 1,
          },
          {
            title: "I blame myself all the time for my faults. "
            ,
            value: 2,
          },
          {
            title: "I blame myself for everything bad that happens." ,
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I don't have any thoughts of killing myself.",
            value: 0,
          },
          {
            title: "I have thoughts of killing myself, but I would not carry them out.",
            value: 1,
          },
          {
            title: "I would like to kill myself.",
            value: 2,
          },
          {
            title: "I would kill myself if I had the chance. ",
            value: 3,
          },
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I don't cry any more than usual. ",
            value: 0,
          },
          {
            title: "I cry more now than I used to. ",
            value: 1,
          },
          {
            title: "I cry all the time now.",
            value: 2,
          },
          {
            title: "I used to be able to cry, but now I can't cry even though I want to.",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I am no more irritated by things than I ever was.",
            value: 0,
          },
          {
            title: "I am slightly more irritated now than usual.",
            value: 1,
          },
          {
            title: "I am quite annoyed or irritated a good deal of the time.",
            value: 2,
          },
          {
            title: "I feel irritated all the time. ",
            value: 3,
          },
        ],
      },
      {
        question:
          "Please select how you feel.",
        answer: [
          {
            title: "I have not lost interest in other people.",
            value: 0,
          },
          {
            title: "I am less interested in other people than I used to be.",
            value: 1,
          },
          {
            title: "I have lost most of my interest in other people. ",
            value: 2,
          },
          {
            title: "I have lost all of my interest in other people.",
            value: 3,
          },
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I make decisions about as well as I ever could. ",
            value: 0,
          },
          {
            title: "I put off making decisions more than I used to.",
            value: 1,
          },
          {
            title: "I have greater difficulty in making decisions more than I used to.",
            value: 2,
          },
          {
            title: "I can't make decisions at all anymore.",
            value: 3,
          },
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I don't feel that I look any worse than I used to.",
            value: 0,
          },
          {
            title: "I am worried that I am looking old or unattractive.  ",
            value: 1,
          },
          {
            title: "I feel there are permanent changes in my appearance that make me look unattractive.",
            value: 2,
          },
          {
            title: "I believe that I look ugly.",
            value: 3,
          },
        
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I can work about as well as before.   ",
            value: 0,
          },
          {
            title: "It takes an extra effort to get started at doing something.            ",
            value: 1,
          },
          {
            title: "I have to push myself very hard to do anything.",
            value: 2,
          },
          {
            title: "I can't do any work at all.",
            value: 3,
          },
        
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I can sleep as well as usual. ",
            value: 0,
          },
          {
            title: "I don't sleep as well as I used to.",
            value: 1,
          },
          {
            title: "I wake up 1-2 hours earlier than usual and find it hard to get back to sleep. ",
            value: 2,
          },
          {
            title: "I wake up several hours earlier than I used to and cannot get back to sleep.             ",
            value: 3,
          },
          
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I don't get more tired than usual.             ",
            value: 0,
          },
          {
            title: "I get tired more easily than I used to. ",
            value: 1,
          },
          {
            title: "I get tired from doing almost anything.  ",
            value: 2,
          },
          {
            title: "I am too tired to do anything.",
            value: 3,
          },
          
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "My appetite is no worse than usual.  ",
            value: 0,
          },
          {
            title: "My appetite is not as good as it used to be. ",
            value: 1,
          },
          {
            title: "My appetite is much worse now. ",
            value: 2,
          },
          {
            title: " I have no appetite at all anymore. ",
            value: 3,
          },
          
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I haven't lost much weight, if any, lately. ",
            value: 0,
          },
          {
            title: " I have lost more than five pounds.",
            value: 1,
          },
          {
            title: "I have lost more than ten pounds.  ",
            value: 2,
          },
          {
            title: "I have lost more than fifteen pounds.",
            value: 3,
          },
          
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I am no more worried about my health than usual.    ",
            value: 0,
          },
          {
            title: "I am worried about physical problems like aches, pains, upset stomach, or constipation.   ",
            value: 1,
          },
          {
            title: "I am very worried about physical problems and it's hard to think of much else.             ",
            value: 2,
          },
          {
            title: "I am so worried about my physical problems that I cannot think of anything else.             ",
            value: 3,
          },
          
        ],
      },
      {
        question: "Please select how you feel.",
        answer: [
          {
            title: "I have not noticed any recent change in my interest in sex.  ",
            value: 0,
          },
          {
            title: "I am less interested in sex than I used to be.   ",
            value: 1,
          },
          {
            title: "I have almost no interest in sex.    ",
            value: 2,
          },
          {
            title: "I have lost interest in sex completely. ",
            value: 3,
          },
          
        ],
      },
      
    ],
  },


  historyIntro: {
    type: "audioPlay",
    title: "Welcome ###name### to your history session",
    description: "",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/1%20WHAT%20IS%20EMDR.mp3",
    complete: {
      title: "Start History",
      slide: "historyDES",
    },
  },

  historyDES: {
    type: "patientDES",
    complete: {
      title: "Submit",
      slide: "historyInitial",
    },
  },

  historyResults: {
    type: "desc",
    title:"Cutoff point",
    description:"Text will be added later, from here they will be taken to the homepage",
    complete: {
      title: "Next",
      slide: "historyInitial",
    },
  },

  historyInitial: {
    type: "patientHistory",
    complete: {
      title: "Submit",
      slide: "historyChecklist",
    },
  },

  historyChecklist: {
    type: "patientChecklist",
    complete: {
      title: "Submit",
      slide: "historyBreathExercise",
    },
  },

  historyBreathExercise: {
    type: "audioPlay",
    title: "Let's do some deep breathing",
    description:
      "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    audio:"https://storage.googleapis.com/savyn-public/New%20-%20Square%20Breathing.mp3",
    complete: {
      title: "Continue",
      slide: "historySafeSpace",
    },
  },

  historySafeSpace: {
    type: "yesNo",
    title: "Did you want to visit your calm place?",
    yes: {
      slide: "visitCalmPlace",
      bookmarks: [
        {
          bookMark: "emdrReturn",
          slide: "historyEnding",
        },
      ],
    },
    no: {
      slide: "historyEnding",
    },
  },

  historyEnding: {
    type: "audioPlay",
    title: "History Session Wrap Up",
    description: "",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/2%20history%20taking%20session%20end%20script.mp3",
    complete: {
      title: "Next",
      slide: "historyConsent",
    },
  },

  historyConsent: {
    type: "consetForm",
    back: {
      title: "Go Back",
      slide: "homePage",
    },
    complete: {
      title: "Complete History",
      slide: "homePage",
      action: {
        type:"setConsent",
      }
    },
  },

  traumaCreator: {
    type: "traumaCreator",
    complete: {
      title: "Save",
      slide: "emdrChoose",
      action: {
        type: "setTrigger",
      },
    },
  },

  traumaCreatorEmdr: {
    type: "traumaCreator",
    complete: {
      title: "Save",
      slide: "traumaWarning",
      action: {
        type: "setTrigger",
      },
    },
  },

  traumaWarning: {
    type: "desc",
    title: "Please Wait 7 Days",
    description:
      "EMDR can put pressure on your mental health we suggest waiting 7 days between session.",
    complete: {
      slide: "emdr_29",
    },
  },

  containerIntro: {
    type: "desc",
    title: "Container Journey",
    description:
      "We will walk you through the process of creating a container. This will be a safe space to put your thoughts and emotions in order to put them out of mind.",
    complete: {
      title: "Next",
      slide: "containerInstructions",
    },
  },
  containerInstructions: {
    type: "desc",
    title: "Let's begin",
    description:
      "Can you think of a container that is in front of you? It’s a container that has a lid. It’s a lid that can be securely fastened. We are going to use it to hold your thoughts, and memories, and painful experiences. It needs to be respectful of those experiences, so it shouldn’t be something like a coffin or garbage can. Now, have you got a container in mind?",
    complete: {
      title: "Next",
      slide: "createContainer",
    },
  },
  createContainer: {
    type: "multiButton",
    title: "How would you like to record your container?",
    description: "",
    buttons: [
      {
        title: "Write details about your container",
        slide: "writeContainer",
      },
      /*{ commented out b/c record audio not working properly
        title: "Record details about your container",
        slide: "recordContainer",
      },*/
    ],
  },
  writeContainer: {
    type: "writeContainer",
    title:
      "Can you describe it? In as much detail as you'd like describe your container.",
    description: "",
    quit: {
      slide: "homePage",
    },
    save: {
      slide: "installInst",
    },
  },

  recordContainer: {
    type: "recordAudioContainer",
    title: "Can you describe it? In as much detail as you'd like describe your container.",
    description: "1. Step 1 2. Step 2",
    quit: {
      slide: "installInst",
    },
    save: {
      slide: "installInst",
    },
  },

  installInst: {
    type: "audioPlay",
    title: "Container installation instructions",
    description: "Imagine your container",
    audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/14%20container%20installation.mp3",
    complete: {
      title: "Next",
      slide: "installContainer",
    },
  },

  installContainer: {
    type: "video",
    title: "Install container with slow vertical eye movements",
    description: "",
    video: "https://storage.googleapis.com/savyn-public/Up%20And%20Down.mp4",
    complete: {
      title: "Next",
      slide: "useContainer",
    },
  },
useContainer:{
  type:"audioPlay",
  title:"Using your container",
  description:"When you remember a distressing event recall your container by the cue word. Imagine the lid is off the container. Not in any great detail, think about all the experiences, memories, and any concerns you may have. Do not think about anything at great length, see it, name it and one by one put it into the container. Place the lid onto your container and close it. Imagine putting the container away, knowing that those memories are safely put away inside your container. Imagine putting the container somewhere out of your direct view, knowing that it's there if you need it, but for now all those memories are safely put away inside your container.",
  audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/15%20using%20your%20container.mp3",
  complete:{
    title:"Next",
    slide:"howDidItFeel"
    // slide:"emdrChoose",
    // bookMark:"fromHomePageContainer",
  },
},


  haveSafeSpace: {
    type: "yesNo",
    title: "Do you have a calm place you can go to?",
    yes: {
      slide: "createSafeSpace",
    },
    no: {
      slide: "guidedJourney",
    },
  },

  safeSpaceDesc: {
    type: "audioPlay",
    title: "Calm Place",
    description: "We will talk you through the idea of finding a calm place",
    audio:"https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/8%20finding%20a%20calm%20place.mp3", 
    complete: {
      title: "Begin Journey",
      slide: "writeDownDetails",
    },
  },

  guidedJourney: {
    type: "yesNo",
    title: "Guided calm place discovery",
    description:
      "Do you remember a time when you’ve felt safe? It can be a physical space in nature or an imagined space.",
    yes: {
      slide: "safeSpaceDesc",
    },
    no: {
      action: {
        type:"preRecordSpace",
      },
      slide: "preRecordSpace",
    },
  },

  createSafeSpace: {
    type: "multiButton",
    title: "How would you like to record your Calm Place?",
    description: "",
    buttons: [
      {
        title: "Write your own Calm Place",
        slide: "writeDownDetails",
      },
      /*{ commented out b/c record audio not working properly
        title: "Record your own Calm Place",
        slide: "recordDetails1",
      },*/

    ],
  },

  addJournal:{
    type:"addJournal",
    quit: {
      slide: "emdr_list",
    },
    save: {
      slide: "reviewJournals",
    },
  },

  journalEntries: {
    url: "/journal",
    type: "journalEntries",
    title: "Your written exposure therapy sessions",
    entries: [
      {
        title: "Session One ",
        slideTitle: "Welcome to your written exposure therapy session",
        slideDesc: "What to expect and psychoeducation.",
        slideWrite: "Let your mind flow and write what ever you want.",
      },
      {
        title: "Fear Focus",
        slideTitle: "Now Let's Focus on something more specific",
        slideDesc: "This is a Calm Place to record your feelings.",
        slideWrite: "What is the focus of your fear",
      },
    ],
    complete: {
      title: "Go to Homepage",
      slide: "homePage",
    },
  },

wet_list:{
  url:"/wet",
  type:"multiButton",
  title:"Your written exposure therapy sessions",
  description:"",
  buttons:[
    {
      title:"Session 2",
      slide:"assessment_wet",
      meta:"wetSession",
    },
    {
      title:"Session 1",
      slide:"assessment_wet"
    },
    {
      title: "Back to Savyn",
      slide: "homePage",
    },
  ]
},

assessment_wet: {
  type: "assessment",
  endPoint: {
    title: "Screening Questionnaire",
    value: {
      0: {
        question:
          "Your assessment results are not indicative of symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. A score of 1 indicates you are managing your stress symptoms well.",
        answer: [
          {
            title: "Continue",
            slide: "bdiAssessment_wet",
          },
        ],
      },
      25: {
        question:
          "Your assessment results are indicative of moderate symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. A score of 1 indicates you are managing your stress symptoms well. ",
        answer: [
          {
            title: "Continue",
            slide: "bdiAssessment_wet",
          },
        ],
      },
      50: {
        question:
          "Your assessment results are indicative of severe symptoms of PTSD. Your reactions are normal as many people who experience a trauma have this kind of reactions. People who scored high on the assessment like you did can benefit from talking to a healthcare provider directly. Seeking help is very important if you think you may have other trauma-related problems that this assessment would not identify, such as depression, substance abuse or anxiety.",
        answer: [
          {
            title: "Find local health care provider",
            value: "https://www.ontario.ca/page/find-mental-health-support",
            method: "Jump",
          },
        ],
      },
    },
  },

  questions: [
    {
      title: "Screening Questionnaire",
      question:
        "On the following screens are some issues that people sometimes have as a response to encountering stressful life events. Please read each statement carefully, then select the option that best describes how much you have been bothered by each problem in the past month. Please select only one answer for each question.",
      answer: [
        {
          title: "Begin",
          value: 0,
        },
      ],
    },
    {
      question:
        "Have you had repeated, disturbing memories, thoughts, or images of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you had repeated, disturbing dreams of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Were you suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you felt very upset when something reminded you of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Were you having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you been avoiding thinking or talking about a stressful experience from the past or avoided having feelings related to it?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you been avoiding activities or situations because they remind you of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you had trouble remembering important parts of a stressful experience from the past?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Do you feel a loss of interest in things that you used to enjoy?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Have you been feeling distant or cut off from other people?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you been feeling emotionally numb or being unable to have loving feelings for those close to you?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question:
        "Have you been feeling as if your future will somehow be cut short?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Have you had trouble falling or staying asleep?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Have you been feeling irritable or having angry outbursts?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Are you having difficulty concentrating?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Are you being “super alert” or watchful on guard?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
    {
      question: "Have you been feeling jumpy or easily startled?",
      answer: [
        {
          title: "Not at all",
          value: 1,
        },
        {
          title: "A little bit",
          value: 2,
        },
        {
          title: "Moderately",
          value: 3,
        },
        {
          title: "Quite a bit",
          value: 4,
        },
        {
          title: "Extremely",
          value: 5,
        },
      ],
    },
  ],
},
bdiAssessment_wet: {
  type: "bdiAssessment",
  _slide:"bdiAssessment_wet",
  endPoint: {
    title: "BDI Screening Questionnaire",
    value: {
      0: {
        question:
          "No symptoms of depression ",
        answer: [
          {
            title: "Continue",
            slide: "wet_1",
          },
        ],
      },
      10: {
        question:
          "These ups and downs are considered normal.",
          answer: [
            {
              title: "Continue",
            slide: "wet_1",
            },
          ],
      },
      16: {
        question:
          "Mild mood disturbance.",
        answer: [
          {
            title: "Continue",
            slide: "wet_1",
          },
        ],
      },
      30: {
        question:
          "Moderate depression. ",
        answer: [
          {
            title: "Continue",
            slide: "wet_1",
          },
          
        ],
      },
      40: {
        question:
          "Severe depression.",
        answer: [
          {
            title: "Find local health care provider",
            value: "https://www.ontario.ca/page/find-mental-health-support",
            method: "Jump",
          },
          
        ],
      },
      63: {
        question:
          "Extreme depression. ",
        answer: [
                {
                  title: "Find local health care provider",
                  value: "https://www.ontario.ca/page/find-mental-health-support",
                  method: "Jump",
                }
          
        ],
      },
    },
  },

  questions: [
    {
      title: "BDI Screening Questionnaire",
      question:
        "On the following screens are questions to assess your mood as a response to the issues you’ve faced. Please read each statement carefully, then select the option that best describes how you feel. Please select only one answer for each question.",
      answer: [
        {
          title: "Begin",
          value: 0,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I do not feel sad.",
          value: 0,
        },
        {
          title: "I feel sad.",
          value: 1,
        },
        {
          title: "I am sad all the time and I can't snap out of it. ",
          value: 2,
        },
        {
          title: "I am so sad and unhappy that I can't stand it.",
          value: 3,
        },
        
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I am not particularly discouraged about the future. ",
          value: 0,
        },
        {
          title: "I feel discouraged about the future.",
          value: 1,
        },
        {
          title: " I feel I have nothing to look forward to.",
          value: 2,
        },
        {
          title: "I feel the future is hopeless and that things cannot improve.",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I do not feel like a failure.",
          value: 0,
        },
        {
          title: "I feel I have failed more than the average person. ",
          value: 1,
        },
        {
          title: "As I look back on my life, all I can see is a lot of failures. ",
          value: 2,
        },
        {
          title: "I feel I am a complete failure as a person. ",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I get as much satisfaction out of things as I used to. ",
          value: 0,
        },
        {
          title: "I don't enjoy things the way I used to. ",
          value: 1,
        },
        {
          title: "I don't get real satisfaction out of anything anymore.",
          value: 2,
        },
        {
          title: "I am dissatisfied or bored with everything.",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I don't feel particularly guilty. ",
          value: 0,
        },
        {
          title: "I feel guilty a good part of the time.",
          value: 1,
        },
        {
          title: "I feel quite guilty most of the time.",
          value: 2,
        },
        {
          title: "I feel guilty all of the time.",
          value: 3,
        },
        
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I don't feel I am being punished.",
          value: 0,
        },
        {
          title: "I feel I may be punished. ",
          value: 1,
        },
        {
          title: "I expect to be punished.",
          value: 2,
        },
        {
          title: " I feel I am being punished. ",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I don't feel disappointed in myself.",
          value: 0,
        },
        {
          title: "I am disappointed in myself.",
          value: 1,
        },
        {
          title: "I am disgusted with myself.",
          value: 2,
        },
        {
          title: "I hate myself. ",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I don't feel I am any worse than anybody else.",
          value: 0,
        },
        {
          title: " I am critical of myself for my weaknesses or mistakes. ",
          value: 1,
        },
        {
          title: "I blame myself all the time for my faults. "
          ,
          value: 2,
        },
        {
          title: "I blame myself for everything bad that happens." ,
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I don't have any thoughts of killing myself.",
          value: 0,
        },
        {
          title: "I have thoughts of killing myself, but I would not carry them out.",
          value: 1,
        },
        {
          title: "I would like to kill myself.",
          value: 2,
        },
        {
          title: "I would kill myself if I had the chance. ",
          value: 3,
        },
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I don't cry any more than usual. ",
          value: 0,
        },
        {
          title: "I cry more now than I used to. ",
          value: 1,
        },
        {
          title: "I cry all the time now.",
          value: 2,
        },
        {
          title: "I used to be able to cry, but now I can't cry even though I want to.",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I am no more irritated by things than I ever was.",
          value: 0,
        },
        {
          title: "I am slightly more irritated now than usual.",
          value: 1,
        },
        {
          title: "I am quite annoyed or irritated a good deal of the time.",
          value: 2,
        },
        {
          title: "I feel irritated all the time. ",
          value: 3,
        },
      ],
    },
    {
      question:
        "Please select how you feel.",
      answer: [
        {
          title: "I have not lost interest in other people.",
          value: 0,
        },
        {
          title: "I am less interested in other people than I used to be.",
          value: 1,
        },
        {
          title: "I have lost most of my interest in other people. ",
          value: 2,
        },
        {
          title: "I have lost all of my interest in other people.",
          value: 3,
        },
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I make decisions about as well as I ever could. ",
          value: 0,
        },
        {
          title: "I put off making decisions more than I used to.",
          value: 1,
        },
        {
          title: "I have greater difficulty in making decisions more than I used to.",
          value: 2,
        },
        {
          title: "I can't make decisions at all anymore.",
          value: 3,
        },
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I don't feel that I look any worse than I used to.",
          value: 0,
        },
        {
          title: "I am worried that I am looking old or unattractive.  ",
          value: 1,
        },
        {
          title: "I feel there are permanent changes in my appearance that make me look unattractive.",
          value: 2,
        },
        {
          title: "I believe that I look ugly.",
          value: 3,
        },
      
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I can work about as well as before.   ",
          value: 0,
        },
        {
          title: "It takes an extra effort to get started at doing something.            ",
          value: 1,
        },
        {
          title: "I have to push myself very hard to do anything.",
          value: 2,
        },
        {
          title: "I can't do any work at all.",
          value: 3,
        },
      
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I can sleep as well as usual. ",
          value: 0,
        },
        {
          title: "I don't sleep as well as I used to.",
          value: 1,
        },
        {
          title: "I wake up 1-2 hours earlier than usual and find it hard to get back to sleep. ",
          value: 2,
        },
        {
          title: "I wake up several hours earlier than I used to and cannot get back to sleep.             ",
          value: 3,
        },
        
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I don't get more tired than usual.             ",
          value: 0,
        },
        {
          title: "I get tired more easily than I used to. ",
          value: 1,
        },
        {
          title: "I get tired from doing almost anything.  ",
          value: 2,
        },
        {
          title: "I am too tired to do anything.",
          value: 3,
        },
        
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "My appetite is no worse than usual.  ",
          value: 0,
        },
        {
          title: "My appetite is not as good as it used to be. ",
          value: 1,
        },
        {
          title: "My appetite is much worse now. ",
          value: 2,
        },
        {
          title: " I have no appetite at all anymore. ",
          value: 3,
        },
        
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I haven't lost much weight, if any, lately. ",
          value: 0,
        },
        {
          title: " I have lost more than five pounds.",
          value: 1,
        },
        {
          title: "I have lost more than ten pounds.  ",
          value: 2,
        },
        {
          title: "I have lost more than fifteen pounds.",
          value: 3,
        },
        
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I am no more worried about my health than usual.    ",
          value: 0,
        },
        {
          title: "I am worried about physical problems like aches, pains, upset stomach, or constipation.   ",
          value: 1,
        },
        {
          title: "I am very worried about physical problems and it's hard to think of much else.             ",
          value: 2,
        },
        {
          title: "I am so worried about my physical problems that I cannot think of anything else.             ",
          value: 3,
        },
        
      ],
    },
    {
      question: "Please select how you feel.",
      answer: [
        {
          title: "I have not noticed any recent change in my interest in sex.  ",
          value: 0,
        },
        {
          title: "I am less interested in sex than I used to be.   ",
          value: 1,
        },
        {
          title: "I have almost no interest in sex.    ",
          value: 2,
        },
        {
          title: "I have lost interest in sex completely. ",
          value: 3,
        },
        
      ],
    },
    
  ],
},
wet_1:{
  type:"setSUDS",
  title:"How distressed do you feel now?",
  description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
  complete:{
    slide:"wet_2"
  },

},

wet_2:{
  type:"audioPlay",
  title:"Psychoeducation",
  description:"Posttraumatic Stress Disorder, or PTSD, is a mental health condition that can occur after experiencing or witnessing a life-threatening event. These events are sometimes called trauma and can happen to either one’s self or by witnessing something happen to someone else...",
  complete:{
    title:"Next",
    slide:"wet_3"
  }

},
wet_3:{
  type:"audioPlay",
  title:"Written exposure therapy and what to expect",
  description:"Recall a trauma account that you would like to focus on. This event will be focused on in this session as well as all the proceeding sessions to come.",
  audio: "",
  complete: {
    title: "Next",
    slide: "wet_4",
  },
},
// wet_8:{
//   type:"writeWet",
//   title:"Notice any changes in the emotions and sensations you are feeling.",
//   time:5000,
//   complete:{
//     slide:"useCalmPlace"
//   }

// },

wet_4:{
  type:"writeWet",
  title:"Written exposure therapy",
  description:"Write about the trauma in detail; any sights, sounds, smells, thoughts and feelings you may think of when recalling this event. It is important to write about the specifics of what happened and what you were feeling and thinking as the trauma was happening. Write about your focused trauma using a distanced perspective as you look back upon it now rather than writing about it in the present tense or as if it were happening now. Focus on the writing without concerns of grammar or spelling, and I want you to really delve and explore your very deepest emotions and thoughts as you write about the trauma.",
  complete:{
    title:"Next",
    slide:"wet_5"
  },
},
wet_5:{
  type:"setSUDS",
  title:"How distressed do you feel now?",
    description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
  complete:{
    slide:"wet_6"
  },

},

// wet_3:{
//   type:"writeDown",
//   title: "Written exposure therapy",
//     description: "Recall a trauma account that you would like to focus on. This event will be focused on in this session as well as all the proceeding sessions to come. I want you to write about the trauma in detail; any sights, sounds, smells, thoughts and feelings you may think of when recalling this event. It is important to write about the specifics of what happened and what you were feeling and thinking as the trauma was happening. Write about your focused trauma using a distanced perspective as you look back upon it now rather than writing about it in the present tense or as if it were happening now. Focus on the writing without concerns of grammar or spelling, and I want you to really delve and explore your very deepest emotions and thoughts as you write about the trauma.",
//     quit: {
//       slide: "visitSafeSpace",
//     },
//     save: {
//       slide: "descInstall",
//     },
  
// },
wet_6:{
  type: "multiAudio",
  title: "Let's do some deep breathing",
  description:
    "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
  audio: [
    {
      title: "1 Minute",
      audio:
        "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    },
    {
      title: "3 Minute",
      audio:
        "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    },
  ],
  complete: {
    title: "Next",
    slide: "visitSafeSpace",
    bookmarks: [
      {
        bookMark: "emdrReturn",
        slide: "howSafeDoYouFeel",
      },
    ],
  },
  },
// wet_7: {
//   type:"audioPlay",
//   title:""

// },


  breathExercise: {
    url: "/breath",
    type: "audioPlay",
    title: "Let's do some deep breathing",
    description:
      "Listen and practice to our breathing exercises so you are equipped to handle stress and anxiety.\nDeep breathing produces a calming response in our body and will make you feel better.\nWe use the box breathing technique that research has proven to be very effective.",
    audio:"https://storage.googleapis.com/savyn-public/New%20-%20Square%20Breathing.mp3",
    // audio: [
    //   {
    //     title: "1 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "3 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    //   {
    //     title: "7 Minute",
    //     audio:
    //       "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
    //   },
    // ],
    complete: {
      title: "Go to Homepage",
      slide: "homePage",
    },
  },
  howSafeDoYouFeel: {
      type: "multiButton",
      title: "How did you feel using the calm place?",
    description: "",
    buttons: [
      {
        title: "Completely calm",
        slide: "selectMood",
      },
      {
        title: "A little bit calm",
        slide: "selectMood",
      },
      {
        title: "I need to work on it.",
        slide: "selectMood",
      },
      {
        title: "Not calm.",
        slide: "notCalm",
      },
    ],
  },

  notCalm: {
    type: "multiButton",
    title: "Not calm",
  description: "",
  buttons: [
    {
      title: "Go to calm place",
      slide: "visitSafeSpace",
    },
   
  ],
},


  howDidItFeel: {
    type: "multiButton",
    title: "How secure did your container feel?",
    description: "",
    buttons: [
      {
        title: "Completely secure",
        slide: "selectMood",
      },
      {
        title: "A little bit secure",
        slide: "selectMood",
      },
      {
        title: "I need to work on it",
        slide: "selectMood",
      },
      {
        title: "Not secure",
        slide: "selectMood",
      },
    ],
  },

  testSlide: {
    type: "testTemplate",
    complete: {
      title: "Go to Homepage",
      slide: "homePage",
    },
  },

  homePage: {
    type: "homePage",
    url: "/",
    title: "Welcome ###name###",
    buttons: [
      {
        title: "Breathing Exercise",
        slide: "breathExercise",
      },
      {
        title: "PTSD & Triggers",
        slide: "ptsdTriggers",
      },
      // {
      //   title: "Calm place",
      //   slide: "visitSafeSpace",
      // },
      // {
      //   title: "Container",
      //   slide: "containerIntro",
      // },
      {
        title: "Calm Place",
        slide: "visitSafeSpace",
        meta: "calmSpace",
        bookmarks: [
          {
            bookMark: "fromHomePageCalm",
            slide: "howSafeDoYouFeel",
          },
          {
            bookMark: "emdrReturn",
            slide: undefined,
          },

        ],
      },
      {
        title: "Container",
        slide:"visitContainer",
        // slide: "containerIntro",
        // bookmarks: [
        //   {
        //     bookMark: "fromHomePageContainer",
        //     slide: "howDidItFeel",
        //   },
        //   {
        //     bookMark: "emdrReturn",
        //     slide: undefined,
        //   },
        // ],
        meta: "container",
        containerList: {
          title: "Container",
          slide: "visitContainer",
        },
      },
      {
        title:"Written Exposure Therapy",
        slide:"wetChoose",
        // meta:"wet",
      },
      // {
      //   title: "Written Exposure Therapy",
      //   slide: "journalEntries",
      // },
      {
        title: "EMDR",
        slide: "emdrChoose",
        meta:"emdr",
        bookmarks: [
          {
            bookMark:"fromHomePageContainer",
            slide:"howDidItFeel",
          },
          {
            bookMark: "fromHomePageEMDR",
            slide: "howSafeDoYouFeel",
          },

          {
            bookMark: "emdrReturn",
            slide: "emdrChoose",
          },
        ],
        // bookmarks:[
        //   {
        //     bookMark:"fromHomePageEMDR",
        //     slide:undefined
        //   }
        // ]
      },
      {
        title: "Assessment",
        slide: "assessment",
      },
    ],
  },
  ptsdTriggers:{
    type:"audioPlay",
    title:"PTSD and Triggers",
    description:"Many people experience trauma differently, and triggers can be anything that brings the traumatic memory back to you. Listen to our audio script to understand more about trauma and how you can identify your triggers.",
    audio:"https://storage.googleapis.com/savyn-public/TraumaAndTriggers.mp3",
    complete:{
      title:"Go to homepage",
      slide:"homePage"
    }
  },

  therapistHome:{
    type:"therapistHome",
    title:"Welcome therapist",
    buttons: [
      {
        title: "Shanon Dale ",
        slide: "patient",
      },
      {
        title:"George Fields",
        slide:"patient"
      },
      {
        title:"Lindsey Stroud",
        slide:"patient"
      },
      {
        title:"Sarah O'Donald",
        slide:"patient"
      },
     

    ]
    
  },

  patient:{
    type:"dashboard",
    title:"",
    buttons: [
      {
        title: "Assessment history",
        slide: "dashboards",
      },
      {
        title:"Calm place dashboard",
        slide:"calmDashboard"
      },
      {
        title:"Container dashboard",
        slide:"containerDashboard"
      },
      {
        title:"WET dashboard",
        slide:"wetDashboard"
      },
      {
        title:"EMDR dashboard",
        slide:"emdrDashboard"
      },

    ]
    
  },
  dashboards:{
    type:"dashboard",
    title:"Assessment history",
    buttons: [
      {
        title: "PCL Assessment ",
        score:"Score: 45",
        slide: "patient",
      },
      {
        title:"DES Assessment",
        score:"Score: 24",
        slide:"breathingExercise"
      },
      {
        title:"BDI Assessment",
        score:"Score: 37",
        slide:"breathingExercise"
      },
      {
        title:"History",
        finished:"Completed",
        slide:"breathingExercise"
      },
      {
        title:"PTSD and triggers",
        finished:"Completed",
        slide:"breathingExercise"
      },


    ]
    

  },
  calmDashboard:{
    type:"dashboard",
    title:"Calm Place dashboard",
    buttons: [
      {
        title: "Number of minutes spent in their calm place ",
        score:"Latest update: 10 minutes",
        slide: "patient",
      },
      {
        title:"How calm did they feel? ",
        score:"Latest update: Very calm",
        slide:"patient"
      },
      {
        title:"Mood check in ",
        score:"Latest update: Very good",
        slide:"patient"
      },
 


    ]
    

  },
  containerDashboard:{
    type:"dashboard",
    title:"Container dashboard",
    buttons: [
      {
        title: "Number of minutes spent using their container ",
        score:"Latest update: 10 minutes",
        slide: "patient",
      },
      {
        title:"How secure did their container feel? ",
        score:"Latest update: Not secure",
        slide:"patient"
      },
      {
        title:"Mood check in ",
        score:"Latest update: Bad",
        slide:"patient"
      },
 


    ]
    

  },
  wetDashboard:{
    type:"dashboard",
    title:"Written exposure therapy dashboard",
    buttons: [
      {
        title:"Number of sessions completed ",
        score:"Latest update: 2",
        slide: "patient",
      },
      {
        title:"SUDs: Pre-session",
        score:"Latest update: 5",
        slide:"patient"
      },
      {
        title:"SUDs: Post-session",
        score:"Latest update: 5",
        slide:"patient"
      },
      {
        title:"PCL assessment",
        score:"score: 45",
        slide:"patient"
      },
      {
        title:"BDI assessment",
        score:"score: 30",
        slide:"patient"
      },
 


    ]
    

  },
  emdrDashboard:{
    type:"dashboard",
    title:"EMDR dashboard",
    buttons: [
      {
        title:"Number of sessions completed ",
        score:"Latest update: 2",
        slide: "patient",
      },
      {
        title:"Number of minutes spent on breathing exercises",
        score:"Latest update: 5 minutes",
        slide:"patient"
      },
      {
        title:"Number of minutes spent in their calm place",
        score:"Latest update: 5 minutes",
        slide:"patient"
      },
      {
        title:"SUDs",
        score:"Latest update: 5",
        slide:"patient"
      },
      
      {
        title:"VOC",
        score:"Latest update: 4",
        slide:"patient"
      },
      {
        title:"Overall Mood",
        score:"Good",
        slide:"patient"
      },
 


    ]
    

  },

  visitContainer: {
    url:"/container",
    type: "visitContainer",
  },

  modifySafeSpace: {
    type: "multiButton",
    title: "How would you like to add to your calm place?",
    description: "",
    buttons: [
      {
        title: "Write your own Calm Place",
        slide: "writeDownDetails",
      },
      {
        title: "Record your own Calm Place",
        slide: "recordDetails1",
      },
    ],
  },
  preRecordedCalmPlace:{
    type:"dropDown",
    title:"Pre-recorded calm places",
    audio: [
      {
        title: "Beach",
        audio:"https://storage.googleapis.com/savyn-public/CalmPlaceBeach.mp3",
          // "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
      },
      {
        title: "Meadows",
        audio:"https://storage.googleapis.com/savyn-public/CalmPlaceGrass.mp3",
          // "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
      },
    ],
    complete: {
      title: "Next",
      slide: "descInstall",
    }
  }, 
  preRecordSpace: {
    type: "dropDown",
    title: "Pre-Recorded Calm Places",
    description:
      "You can keep thinking about a calm place. When you are ready come back to Savyn and record this Calm Place so you can access it anytime you want it. In the meantime here are some Calm Place for you to consider that are prerecorder for you.",
      audio: [
        {
          title: "Beach",
          audio:"https://storage.googleapis.com/savyn-public/CalmPlaceBeach.mp3",
        },
        {
          title: "Meadows",
          audio:"https://storage.googleapis.com/savyn-public/CalmPlaceGrass.mp3",
        },
      ], 
    complete: {
      title:"Next",
      slide: "descInstall",
    },
  },
  visitSafeSpace: {
    url: "/calmPlace",
    type: "visitSafeSpace",
  },
  
  visitCalmPlace:{
    type:"visitCalmPlace",
  },
  
  visitEmdrAudio:{
    url:"/emdraudio",
    type:"emdrAudio"
  },
  reviewJournals:{
    type:"reviewJournals",
  },

  // recordDetails: {
  //   type: "recordAudioEmdr",
  //   title: "Record details about your calm place.",
  //   quit: {
  //     slide: "visitSafeSpace",
  //   },
  //   save: {
  //     slide: "visitSafeSpace",
  //   },
  // },

  selectMood: {
    type: "selectMood",
    complete: {
      slide: "homePage",
    },
  },

  setSuds: {
    type: "setSUDS",
    title:"How distressing is it today when you think about what happened at that time?",
    description:"On a scale of 0 to 10, where 0 is you feel no distress and 10 is the highest level of distress you can feel, how distressed do you feel right now?",
    complete: {
      slide: "homePage",
    },
  },

  remeberToJournal: {
    type: "desc",
    title: "Remember To Journal",
    description:
      "If you feel triggered anytime during the week, you can journal.",
    complete: {
      slide: "homePage",
    },
  },

  // there were two "recordDetails" so I changed the name of this one
  recordDetails1: {
    type: "recordAudioEmdr",
    title: "Record details about your calm place.",
    description: "1. Step 1 2. Step1. 2",
    quit: {
      slide: "descInstall",
    },
    save: {
      slide: "descInstall",
    },
  },

  writeDownDetails: {
    type: "writeDown",
    title: "Write down details about your calm place",
    description: "When you are writing or recording your calm place notice the sensations that you are feeling in your body. What are the things you see? What do you hear? What do you see and feel? Notice all of that and write them down",
    quit: {
      slide: "visitSafeSpace",
    },
    save: {
      slide: "descInstall",
    },
  },

  descInstall: {
    type: "audioPlay",
    title: "Calm place installation instructions",
    description: "",
    audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/9%20calm%20place%20installations.mp3",
    complete: {
      title: "Next",
      slide: "installCalmPlace",
    },
  },

  installCalmPlace: {
    type: "video",
    title: "Install calm place with slow vertical eye movements",
    description: "",
    video: "https://storage.googleapis.com/savyn-public/Up%20And%20Down.mp4",
    complete: {
      title: "Next",
      slide: "reinforceCalmPlace",
      // bookMark: "fromHomePageCalm",

    },
  },


  reinforceCalmPlace: {
    type: "audioPlay",
    title:
      "Instructions to use your calm place.",
    description:
      "Use your cue word for your calm place when you want to visit your calm place. When you think of your calm place notice the sensations that you are feeling in your body. Now, think of something that has happened to you in the past week that was mildly annoying or distressing. What emotions are you feeling? Where do you feel it in your body? What are the feelings and bodily sensations that are associated with this memory? On the next screen take a few seconds to notice the feelings and bodily sensations that are associated with this memory, notice them.",
      audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/10%20instructions%20to%20use%20calm%20place.mp3",
      complete: {
      title: "Next",
      slide: "sensationsTimer",
    },
  },
  sensationsTimer:{
    type:"counter",
    title:"Notice any changes in the emotions and sensations you are feeling.",
    time:10000,
    complete:{
      slide:"useCalmPlace"
    }

  },
  useCalmPlace: {
    type: "audioPlay",
    title: "Go to calm place ",
    description:
      "On the next screen, bring up the cue word and go to your calm place. Think about the positive feelings and sensations you felt in your calm place. Notice any shifts in your experience or any changes in your emotions.",
    audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/11%20go%20to%20calm%20place.mp3",
    complete: {
      title: "Next",
      slide: "goCalmTimer",
    },
  },

  goCalmTimer: {
    type: "counter",
    title: "Notice the sensations when you recall your calm place.",
    time: 15000,
    description: "",
    complete: {
      slide: "reinforceCalmPlace_2",
    },
  },

  reinforceCalmPlace_2: {
    type: "audioPlay",
    title: "Can you think of another slightly annoying event?",
    description:
      "On the next screen try doing the same steps on your own. Think about the event, bring up your cue word, and notice the positive sensations that you experience when you go to your calm place.",
    audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/12%20can%20you%20think%20of%20another.mp3",
    complete: {
      title: "Next",
      slide: "reinforceTimer",
    },
  },

  reinforceTimer: {
    type: "counter",
    title: "Think about your calm place.",
    time: 15000,
    description: "",
    complete: {
      title:"Next",
      slide: "practiceCalmPlace",
    },
  },
  practiceCalmPlace: {
    type: "audioPlay",
    title: "Practice using your calm place",
    description:
      "We suggest using your calm place at home every day. Recall your calm place by the cue word whenever you experience stress, anxiety, excessive and intrusive thoughts about an experience or have difficulty falling asleep. This technique will help you to relax during times of stress. Practice using your calm place at home every day by bringing up your cue word and noticing the positive feelings and sensations.",
    audio: "https://storage.googleapis.com/savyn-public/EMDR%20-%20Audio/13%20practice%20using%20calm%20place.mp3",
    complete: {
      title: "Next",
      slide: "howSafeDoYouFeel",
      // bookMark:"fromEMDRCalm",
    },
  },
  testing: {
    type: "testing",
    title: "Testing",
    complete: {
      title: "Go Back",
      slide: "homePage",
    },
  },
  noPage: {
    type: "desc",
    title: "There is no page",
    complete: {
      title: "Go Back",
      slide: "homePage",
    },
  },
  
};


export default where;