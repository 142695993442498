import Navbar from "../Components/Navbar";
import TherapistSidepanel from "../Savyn/TherapistSidepanel";
import { Row } from "react-bootstrap";
import ConvertText from "../Helper/ConvertText";

const TherapistHomepage = ({ state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-therapist min-vh-100">
        <TherapistSidepanel state={state} control={control} slide={slide} />

        <div className="boxTherpist">
          <button
            className="btn float-right btn-primary border text-center btn-md "
            style={{ columnSpan: "all" }}
          >
            {" "}
            Book session
          </button>
          <button className="btn float-right btn-primary border text-center btn-md ">
            {" "}
            Add patient
          </button>

          <h1 className="mt-5 mb-4 text-left">
            {ConvertText(slide.title, state.name)}
          </h1>

          {slide.buttons.map((button, index) => {

            return (
              <div
                key={"Button" + index}
                onClick={() => {
                  if (button.meta === "container") {
                    if (state.containers.length > 0) {
                      control.goSlide(button.containerList);
                      return;
                    }
                  }
                  control.goSlide(button);
                }}
                style={{
                  fontSize: "14px",
                  maxWidth: "400px",
                  maxHeight: "60px",
                  minHeight: "65px",
                  margin: "auto",
                  padding: "10px",
                  borderRadius: "30px",
                }}
                className=" mb-3 btn  border border-primary btn-block border text-center btn-lg "
              >
                {button.title}
                <br />
                <span style={{ fontSize: "14px" }} p-0>
                  {button.score}
                </span>
                <span style={{ fontSize: "14px" }}>{button.finished}</span>
              </div>
            );
          })}
        </div>
      </Row>
    </>
  );
};

export default TherapistHomepage;
