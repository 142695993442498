import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";

import { Row } from "react-bootstrap";
import { useState } from "react";

import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const Consent = ({ state, control, slide }) => {
  let [noConsent, setNoConsent] = useState(false);
  let [consent, setConsent] = useState(false);

  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3>Sign consent with Savyn for EMDR</h3>
          <br />

          <div className="my-2">
            <input
              className="my-2"
              type="radio"
              name="rad3"
              id="agree"
              value="agree"
              onClick={() => {
                setNoConsent(false);
                setConsent(true);
              }}
            />{" "}
            I agree to use Savyn's EMDR sessions and understand that I am
            responsible for taking each session. I also understand Savyn is not
            liable for the outcomes of this treatment.
          </div>

          <div className="my-2">
            <input
              type="radio"
              name="rad3"
              id="disagree"
              value="disagree"
              onClick={() => {
                setNoConsent(true);
                setConsent(false);
              }}
            />{" "}
            I disagree.
          </div>

          {noConsent ? (
            <p>
              We are sorry you did not consent to EMDR sessions with Savyn. We
              cannot proceed without your consent. Until you decide you can
              continue to use our breathing exercise and written exposure
              therapy. You can come back to consent anytime within our app to
              use our EMDR sessions.
            </p>
          ) : (
            <></>
          )}

          {consent ? (
            <button
              type="submit"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={async () => {
                await addJSON({
                  variables: {
                    json: JSON.stringify({
                      consent:true,
                      startTime,
                      endTime: new Date().toISOString(),
                      type: slide?.type,
                      slide: slide?._slide,
                    }),
                  },
                });
                control.goSlide(slide.complete);
              }}
            >
              {slide.complete.title}
            </button>
          ) : (
            <></>
          )}

          {!consent ? (
            <button
              type="submit"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={() => {
                control.goSlide(slide.back);
              }}
            >
              {slide.back.title}
            </button>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
};
export default Consent;
