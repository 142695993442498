
import gql from "graphql-tag";

/*
addMessage(Stub: Boolean, jwt: String, message: String!): Boolean!
const addMessage =  gql`
  mutation addMessage($uuid: String!, $json:String!) {
    answerAForm(uuid: $uuid, json: $json)
  }
`;
*/

const addDebug = gql`
mutation addDebug($message:JSON!){
  addDebug(message:$message)
}
`;


const createAccount =  gql`
  mutation createAccount($name:String!, $email:String!, $password: String!){
    createAccount(name:$name, email:$email, password:$password){
      id,
      json
    }
  }
`;

const login =  gql`
  mutation login($email:String!, $password: String!){
    login(email:$email, password:$password){
      id,
      json
    }
  }
`;

const getCurrentState = gql`
query{
  getCurrentState{
    id,
    json
  }
}
`;

const addJSON = gql`
 mutation addJSON($json:String!){
  addJSON(json:$json)
  }
`;

const setState = gql`
mutation setState($json:String){
  setState(json:$json)
 }
`;
const saveAudio = gql`
mutation saveAudio($audio:String!){
  saveAudio(audio:$audio)
}
`;

const combine = {
  //  addMessage
  addDebug,
  addJSON,
  saveAudio,
  setState,
  createAccount,
  getCurrentState,
  login,
  
};

export default combine;
