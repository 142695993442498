import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";
const Desc = ({ state, control, slide }) => {
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center my-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>

          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={() => {
              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title ? slide.complete.title : "Next"}
          </button>
        </div>
      </Row>
    </>
  );
};

export default Desc;
