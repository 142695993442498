import Navbar from "../Components/Navbar";
import TherapistSidepanel from "../Savyn/TherapistSidepanel";
import {
  Row
} from "react-bootstrap";
import ConvertText from '../Helper/ConvertText'


const TherapistHomepage = ({ state, control, slide }) => {

  return (
    <>

<Navbar state={state} control={control} slide={slide}/>
      
      <Row className="bg-therapist min-vh-100" >
   
   
      <TherapistSidepanel state={state} control={control} slide={slide}/>
      
   
         <div className="boxTherpist">
          <button className="btn float-right ml-2 btn-primary border text-center btn-md " style={{ columnSpan: "all", borderRadius: "30px" }}> Start a session</button> 
          <button className="btn float-right btn-primary border text-center btn-md " style={{borderRadius: "30px"}}> Add patient</button> 

          <h1 className="mt-5 mb-4 text-left">{ConvertText(slide.title, state.name)}</h1>
  
          {slide.buttons.map((button,index)=>{

            // if (button.meta==="wet"){
                        
            //   if (!state.bdiAssessment===0 && state.consent){
            //     control.goSlide("homePage");
            //     return ;
            //   }
            // }
// Display calm place and container on home page once set up from within emdr 
            // if (button.meta==="calmSpace"){
            
            //   if (!state.entries.length && !state.audio.length){
            //     return <></>
            //   }
            // }
            

            // if (button.meta==='container'){
            //   if (!state.containers.length){
            //     return <></>
            //   }
            // }



              return <div key={"Button"+index} onClick={
                ()=>{
                  if (button.meta==="container"){
                    if (state.containers.length>0){
                      control.goSlide(button.containerList);
                      return;
                    }

                  }
                  control.goSlide(button)
                }
            }
            style = {{fontSize: "14px", maxWidth:'400px', padding:'10px', margin:'auto', borderRadius:"30px"}}
            className=" mb-3 btn  border border-primary btn-block border text-center btn-lg "
            >

              {button.title}
            </div>


            })}
         

  
        </div>
        {/* <div >
          <button className= "btn btn-primary border text-center btn-md p-2 m-3"  style={{ columnSpan: "all" }}>Add patient</button>
          <button className= "btn btn-primary border text-center btn-md p-2 "  style={{ columnSpan: "all" }}>Book session</button>
          </div> */}
        
        </Row>

    
    </>
  );
};

export default TherapistHomepage;
