import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import {
  Form,
  Row,
} from "react-bootstrap";

import { useState } from "react";
import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";

const EMDRList = ({ state, control, slide }) => {
  const [answer, setAnswer] = useState(undefined);

  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);


  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box">
          <h3 className="text-center mb-4">{slide.title}</h3>
          <p>{slide.description}</p>
          <br />

          <div
                key={"button:1"}
                onClick={async(e) => {
   
                  control.goWhere("emdr_1");
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
               Start Session {state.sessionCount+1}
              </div>

              <div
                key={"button:2"}
                onClick={async(e) => {
           
                  control.goWhere("addJournal");
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                Add EMDR journal
              </div>

              <div
                key={"button:3"}
                onClick={async(e) => {
              
                  control.goWhere("reviewJournals");
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                Review your EMDR Journal
              </div>

              <div
                key={"button:4"}
                onClick={async(e) => {
              
                  control.goWhere("homePage");
                }}
                className="btn btn-block btn-lg mb-2 text-center btnStandard"
              >
                Go Back
              </div>
        </div>
      </Row>
    </>
  );
};
export default EMDRList;
