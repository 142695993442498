import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const BDIAssessment = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  let [answers, setAnswers] = useState([]);
  let [question, setQuestion] = useState(0);
  let [value, setValue] = useState(0);

  if (question >= slide.questions.length) {
    let currentQuestion;

    for (let key in slide.endPoint.value) {
      let checkScore = parseInt(key);

      if (checkScore <= value) {
        currentQuestion = slide.endPoint.value[key];
      } else {
        continue;
      }
    }

    return (
      <>
        <Navbar state={state} control={control} slide={slide} />
        <Row className="bg-img min-vh-100">
          <Sidepanel state={state} control={control} slide={slide} />
          <div className="box text-left">
            <h3>{currentQuestion.title}</h3>
            <br />
            <p>{value}/63</p>
            <p>{currentQuestion.question}</p>

            {currentQuestion.answer.map((e, index) => {
              return (
                <div
                  key={"button" + index}
                  onClick={async () => {
                    control.setBDIAssessment(value);
                    await addJSON({
                      variables: {
                        json: JSON.stringify({
                          answers,
                          startTime,
                          endTime: new Date().toISOString(),
                          question,
                          type: slide?.type,
                          slide: slide?._slide,
                          value,
                        }),
                      },
                    });

                    setAnswers([]);
                    setQuestion(0);
                    setValue(0);

                    control.goSlide(e);
                  }}
                  className="btn btn-block text-center btn-lg btnStandard"
                >
                  {e.title}
                </div>
              );
            })}
          </div>
        </Row>
        {/* </div>
          </div> */}
      </>
    );
  }
  let currentQuestion = slide.questions[question];
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3>{currentQuestion.title}</h3>
          <br />
          <p>
            {question}/{slide.questions.length}
          </p>

          <p>{currentQuestion.question}</p>

          {currentQuestion.answer.map((e, index) => {
            return (
              <div
                key={"button" + index}
                onClick={() => {
                  setAnswers([...answers, e.value]);
                  setQuestion(question + 1);
                  setValue(value + e.value);
                }}
                className="btn btn-block text-center btn-lg btnStandard"
              >
                {e.title}
              </div>
            );
          })}
        </div>
      </Row>
      {/* </div>
      </div> */}
    </>
  );
};
export default BDIAssessment;
