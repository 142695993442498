import Navbar from "../Components/Navbar";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const RecordAudioButton = ({ slide, control }) => {
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    setAudioDetails(data);
  };
  const handleAudioUpload = (file) => {
    const objectURL = URL.createObjectURL(file);
    control.saveEmdrAudio(objectURL);
    control.goSlide(slide.quit);
  };
  const handleRest = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  return (
    <>
      <Recorder
        record={true}
        title={"New recording"}
        audioURL={audioDetails.url}
        handleAudioStop={(data) => handleAudioStop(data)}
        handleAudioUpload={(data) => handleAudioUpload(data)}
        handleRest={() => handleRest()}
      />
    </>
  );
};

const RecordEmdrAudio = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);


  const changeSlide = async ()=>{
    await addJSON({
      variables: {
        json: JSON.stringify({
          startTime,
          endTime: new Date().toISOString(),
          type: slide?.type,
          slide: slide?._slide,
        }),
      },
    });
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center">{slide.title}</h3>
          <br />

          <div className="text-center my-3"></div>
          <div className="text-center my-3">
            <RecordAudioButton slide={slide} control={control} />
          </div>

          <div className="float-right mt-4">
            {slide.quit ? (
              <button
                onClick={async () => {
                  await changeSlide();
                  control.goSlide(slide.quit);
                }}
                type="button"
                className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
              >
                Quit
              </button>
            ) : (
              <></>
            )}

            {slide.complete ? (
              <button
                type="button"
                className="btn btn-block text-center btn-lg mt-4 btnStandardMini"
                onClick={async() => {
                  await changeSlide();
                  control.goSlide(slide.complete);
                }}
              >
                Next
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};


/*
ToDo Save Audio
*/

export default RecordEmdrAudio;
