import "./App.css";

import {
  Col,
  Row,
} from "react-bootstrap";

import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import ChatBox from "./Chat/ChatBox";
import Where from "./Savyn/Where";
import State from "./Savyn/State";
import Switcher from "./Savyn/Switcher";
import Debugger from "./Savyn/Debugger";

import useKeypress from "react-use-keypress";

import { useState, useEffect } from "react";

import { HashRouter as Router, useHistory } from "react-router-dom";

import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";

import { useQuery, useMutation } from "@apollo/react-hooks";
import gqlQuery from './GQL';


import {setMegaState} from "./Helper/MegaState";

let clientAddress = "/graphql";
if (process.env.NODE_ENV === "development") {
  clientAddress = "http://localhost:8080/graphql";
}

// Create an http link:
const httpLink = createHttpLink({
  uri: clientAddress,
  headers: {
    "keep-alive": "true",
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});



function App() {
  return (
  <ApolloProvider client={client}>   
    <Router>
      <AppWrapped />
    </Router>
    </ApolloProvider>
  );
  
}

let _newWhere = localStorage.getItem("hackAThon")
  ? JSON.parse(localStorage.getItem("hackAThon"))
  : {
      homePage: {
        type: "homePage",
        url: "/",
        title: "Welcome To The Hackathon",
        buttons: [
          {
            title: "Test Page",
            slide: "testPage",
          },
        ],
      },

      testPage: {
        type: "desc",
        title: "This is a Test Page",
        complete: {
          title: "Back to Home",
          slide: "homePage",
        },
      },
      noPage: {
        type: "desc",
        title: "There is no page",
        complete: {
          title: "Go Back",
          slide: "homePage",
        },
      },
    };

function AppWrapped() {

  let history = useHistory();

  let testAuc;// = JSON.parse(localStorage.getItem("debugState"));
  if (!testAuc) {
    testAuc = {
      slide: "homePage",
      state: new State(),
    };
  }

  let [where, setWhere] = useState(Where);
  let [newWhere, setNewWhere] = useState(_newWhere);
  let [state, _setState] = useState(new State());
  let [_slide, setSlide] = useState(testAuc.slide);
  let [tabPress, setTabPressed] = useState(0);
  let [hackAThon, setHackAThon] = useState(false);

  const [setSession] = useMutation(gqlQuery.setState);

  useKeypress("Escape", () => {
    setTabPressed(tabPress + 1);
  });


  const setState= async (vars, shouldNotSave)=>{
    console.log(vars);
    _setState(vars);
  }



  useEffect(()=>{

    window.addEventListener('beforeunload', async (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    
      console.log("Called");
      await setSession({
        variables: {
          json: JSON.stringify(state),
        },
      });
    });

   


  },[])

  const changeOptions = (vars) => {
    if (vars.state) {
      _setState(vars.state);
    }

    if (vars.slide) {
      _setState(vars.slide);
    }
  };

  if (hackAThon){
    where=newWhere;
  } else {
    setWhere=setNewWhere;
  }

  let slide = where[_slide];
  if (!slide) {
    slide = where["homePage"];
  } else {
  }

  const __slide = {...slide, _slide:_slide};


  if (tabPress % 2) {
    return (
      <Row>
        <Col>
          <Debugger
            setSlide={setSlide}
            where={where}
            setWhere={setWhere}
            history={history}
            state={state}
            _slide={_slide}
            slide={__slide}
            changeOptions={changeOptions}
            testAuc={testAuc}
            slideNameToDisplay={_slide}
            hackAThon={hackAThon} 
            setHackAThon={setHackAThon}
          />
        </Col>
        <Col>
          <InnerApp
            history={history}
            state={state}
            setState={setState}
            slide={slide}
            setSlide={setSlide}
            where={where}
         
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <ChatBox />
      <InnerApp
       hackAThon={hackAThon} 
        history={history}
        state={state}
        setState={setState}
        slide={__slide}
        setSlide={setSlide}
        where={where}
   
      />
    </>
  );
}





function InnerApp({ history, state, setState, slide, setSlide, where, }) {
  return (
 
    <Switcher
      history={history}
      state={state}
      setState={setState}
      slide={slide}
      setSlide={setSlide}
      where={where}
    />

  );
}

export default App;
