import Navbar from '../Components/Navbar';
import Sidepanel from "../Savyn/Sidepanel";
import {
  Row,
} from "react-bootstrap";

import { useState} from "react";

const VisitEmdrAudio = ({ state, control, slide }) => {



  const [selectEmdrAudio, setSelectEmdrAudio] = useState(undefined);

  if  (!state.emdrAudio){
    state.emdrAudio = [];
  }
  // const lastAudio = [state.emdrAudio.length-1]


   if (selectEmdrAudio !== undefined){

    let audioURL = state.emdrAudio[selectEmdrAudio];
    return <>
    <Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" style={{width:"800px"}}>
     
        <h3 className="mb-4">Emdr Audio Entry</h3>
        <p className="mb-4"></p>



        <div className="m-5">
          <audio controls>
          <source src={audioURL} type="audio/mpeg"/>

            Your browser does not support the audio tag.
                    </audio>
        </div>

        <button
          type="button"
          className="btn btn-block border  text-center btn-lg mt-4 "
        
          onClick={() => {
            console.log(audioURL)
            console.log(JSON.stringify(audioURL))
            setSelectEmdrAudio(undefined);
          }}
        >
          Go Back
        </button>
        </div>
        </Row>
      {/* </div>
      </div> */}





  </>

  }

  

  return (
    <>

<Navbar state={state} control={control} slide={slide}/>

<Row className="bg-img min-vh-100" >
{/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
<Sidepanel state={state} control={control} slide={slide}/>
<div className="box text-left" >

          <h3>Emdr audio Library</h3><br />

          
            {
              state.emdrAudio.map((e, index)=>{
                return <div key={"emdrAudio"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{

                  setSelectEmdrAudio(index);
           
              

                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                Emdr audio recording {index+1}
              </div>
              })
            }
{/* 
            {
              state.emdrAudio.map((e, index)=>{
                return <div key={"emdrAudio"+index} className="btn btn-block  text-left btn-lg" onClick={()=>{

                  setSelectEmdrAudio(index);
              

                }}>


                <i className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}></i>
  
                Emdr audio recording {index+1}
              </div>
              })
            } */}


          
          
            <button 
          type="submit" 
          className="btn btn-block text-center btn-lg mt-4 btnStandard"
          onClick = {()=>{
            if(state.bookMark["emdrReturn"]){
              control.goWhere(state.bookMark["emdrReturn"])
            } else {
              control.goWhere("homePage")
            }
          }}
          >
            {state.bookMark["emdrReturn"]?"Next":"Next"}
            
            </button>
            


            </div>
      {/* </div>*/}
</Row>









    </>
  );
};
export default VisitEmdrAudio;