import Navbar from '../Components/Navbar';
import Sidepanel from "../Savyn/Sidepanel";
import {
  Row,
} from "react-bootstrap";
import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";




const EndSession = ({state, control, slide }) => {
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());

  const addToSite = (answer)=>{
    return addJSON({
      variables: {
        json:JSON.stringify({
          answer,
          startTime,
          endTime:new Date().toISOString(),
          type: slide?.type,
          slide: slide?._slide,
        })
      }
    });
  }


  return (
    <>
 <Navbar state={state} control={control} slide={slide}/>

<Row className="bg-img min-vh-100" >
{/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
<Sidepanel state={state} control={control} slide={slide}/>
<div className="box text-left" >
          <h3 className="text-left my-4">Your EMDR Session is now Complete</h3>


  
      <button onClick={async()=>{
        
        addToSite();
        control.increaseSession()
        control.goSlide(slide.complete);
      }} type="button" className="btn btn-block text-center btn-lg mb-2 btnStandard">Complete Session</button>
    


          </div>
          {/* </div> */}
          </Row>
    

    </>
  );
};
export default EndSession;
