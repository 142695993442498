import withoutShowdow from "../Images/without_shadow.png";
import { useState } from "react";

import {Navbar, NavDropdown, Nav, Form} from 'react-bootstrap';

import { useMutation } from "@apollo/react-hooks";
import gqlQuery from "../GQL";

const _Sidepanel = ({ state, control, slide }) => {
  const [setSession] = useMutation(gqlQuery.setState);

  if (state.toggleSideBar){


    return <>
   
    <div className=" d-none d-lg-block bg-white pt-2 pr-3 pl-3 min-vh-100" style={{width:"300px"}}>
    <Navbar.Brand href="/home" className="ml-3 text-dark" >
   <img 
         alt=""
         src={withoutShowdow}
         width="40"
         height="40"
         className="d-inline-block align-top"
       />{' '}
       <h1 style={{fontSize:'30px'}}  className="d-inline-block align-top">SAVYN</h1>
   </Navbar.Brand>
 <ul className=" list-group  " style={{cursor:"pointer"}}>
     
 <li className="list-unstyled m-3  "> <a onClick={()=>{
   control.toggleSideBar(false);
       control.goWhere("homePage");}}  
       class=" text-dark d-lg-block listItem "><i class="fa fa-home p-1 " aria-hidden="true"></i> Home </a></li>
 
     <NavDropdown.Divider />
         <Nav.Link onClick={async()=>{
          await setSession({
            variables: {
              json: JSON.stringify(state),
            },
          });
         control.clearState()
         control.goWhere('homePage')
       }}>Sign out</Nav.Link> 
 </ul>
 </div>
    
 </>


  }

  return <>
   
   <div className=" d-none d-lg-block bg-white pt-2 pr-3 pl-3 min-vh-100" style={{width:"300px"}}>
   <Navbar.Brand href="/home" className="ml-3 text-dark" >
  <img 
        alt=""
        src={withoutShowdow}
        width="40"
        height="40"
        className="d-inline-block align-top"
      />{' '}
      <h1 style={{fontSize:'30px'}}  className="d-inline-block align-top">SAVYN</h1>
  </Navbar.Brand>
<ul className=" list-group  " style={{cursor:"pointer"}}>
    
<li className="list-unstyled m-3  "> <a onClick={()=>{ 
      control.goWhere("homePage");
    }}  
      class=" text-dark d-lg-block listItem "><i class="fa fa-home p-1 " aria-hidden="true"></i> Home </a></li>

    <li className="list-unstyled m-3  "> <a onClick={()=>{
      control.goWhere("breathExercise");}}  
      class=" text-dark d-lg-block listItem "><i class="fa fa-wind p-1 " aria-hidden="true"></i> Breathing exercises </a></li>

    <li className="list-unstyled m-3 " ><a  onClick={()=>{
      control.goWhere("ptsdTriggers");}}
      class="text-dark d-lg-block listItem"><i class="fa fa-info-circle p-1" aria-hidden="true"></i> PTSD and triggers </a></li>

    <li className="list-unstyled m-3 "> <a onClick={()=>{
           control.goWhere("visitSafeSpace");}}
      
        class="text-dark d-lg-block listItem"><i class="fa fa-seedling p-1" aria-hidden="true"></i> Calm place </a></li>

    <li className="list-unstyled m-3"> <a onClick={()=>{
        control.goWhere('visitContainer')}}
        class="text-dark d-lg-block listItem"><i class="fa fa-box archive p-1" aria-hidden="true"></i> Container</a></li>
    <li className="list-unstyled m-3  "> <a onClick={()=>{
        control.goWhere('wetChoose')}}
        class="text-dark d-lg-block listItem"><i class="fa fa-book" aria-hidden="true"></i> Written exposure therapy</a></li>
    
    <li className="list-unstyled m-3  "> <a onClick={()=>{
      
      control.toggleSideBar(true);
        control.goWhere('emdrChoose')}}
        class="text-dark d-lg-block listItem"><i class="fa fa-eye" aria-hidden="true"></i> EMDR</a></li>
    <NavDropdown.Divider />
        <Nav.Link onClick={async()=>{

await setSession({
  variables: {
    json: JSON.stringify(state),
  },
});

        control.clearState()
        control.goWhere('homePage')
      }}>Sign out</Nav.Link> 
</ul>
</div>
   
</>
};
export default _Sidepanel;

