import Navbar from "../Components/Navbar";
import React from "react";
import { useState } from "react";
import Countdown from "react-countdown";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";


const Completionist = () => <>Next</>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    // return <>{(seconds===0)?60:seconds}</>;
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  }
};

const WriteWet = ({ state, control, slide }) => {
  // const [title, setTitle] = useState("");
  const [doc, setDoc] = useState("");
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center my-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>

          <textarea
            onChange={(e) => {
              setDoc(e.target.value);
            }}
            className="form-control border border-secondary mb-3 mt-n3"
            name=""
            rows="12"
          ></textarea>

          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={async () => {

              await addJSON({
                variables: {
                  json:JSON.stringify({
                    answer:doc,
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  })
                }
              });


              control.saveWet(doc);

              control.goSlide(slide.complete);
            }}
          >
            <Countdown date={Date.now() + 1800000} renderer={renderer} />
          </button>
        </div>
      </Row>
      {/* </div>
      </div> */}
    </>
  );
};

export default WriteWet;
