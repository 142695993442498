import { useState, useEffect } from "react";
import {
  Container,
  Button,
  Form,
  Col,
  Row,
  Modal,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import FileSaver from 'file-saver';




import DebugBox from "../Debug/DebugBox";
import EditPage from "./EditPage";
import Map from './Map';

const Debugger = ({
  setSlide,
  where,
  setWhere,
  history,
  state,
  _slide,
  slideNameToDisplay,
  slide,
  changeOptions,
  testAuc,

  hackAThon, 
  setHackAThon
}) => {
  
  let [dateProcess, setDateProcess] = useState(new Date().toString());

  let [slideType, setSlideType] = useState("desc");
  let [slideName, setSlideName] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  ///

  let canDelete = false;






  return (
    <div style={{marginLeft:"20px",marginRight:"20px"}}>
      <div>Debug</div>

      <div>You are on Slide - {slideNameToDisplay}</div>

    

      <Form.Control
        as="select"
        onChange={(e) => {
          setSlide(e.target.value);
        }}
      >
        {Object.keys(where).sort().map((e) => {
          if (e === "noPage") {
            return "";
          }
          return <option key={e}>{e}</option>;
        })}
      </Form.Control>

      {/*_debug ? (
        <DebugBox
          history={history}
          state={state}
          slide={slide}
          changeOptions={changeOptions}
        />
      ) : (
        <></>
      )*/}

      <div style={{marginTop:"15px", marginBottom:"15px"}}>

      <Button
        onClick={() => {
            setSlide("homePage");
         
          setHackAThon(!hackAThon);
        }}
      >
        {!hackAThon ? "Custom Flow" : "Normal"}
      </Button>

<span style={{marginLeft:"20px"}}></span>
  
        <Button
          onClick={() => {
            setShow(true);
          }}
        >
          Edit Page
        </Button>
  

      </div>

 

   

      <div>
        {canDelete ? (
          <Button
            onClick={() => {
              delete where[_slide];
              setDateProcess(new Date().toString());
            }}
          >
            Remove Page
          </Button>
        ) : (
          ""
        )}
      </div>


<div style={{marginTop:"50px"}}></div>
      <Form.Label>Type Of Slide to Create</Form.Label>
      <Form.Control
        as="select"
        onChange={(e) => {
          setSlideType(e.target.value);
        }}
      >
        <option>desc</option>
        <option>yesNo</option>
        <option>selectMood</option>
        <option>setSUDS</option>

        <option>emdr_select</option>
        <option>multiButton</option>
        <option>audioPlay</option>

        <option>multiAudio</option>
        <option>counter</option>
        <option>multiSelect</option>


        <option>video</option>
        <option>recordAudioEmdr</option>
        <option>testTemplate</option>


        <option>scale</option>
        <option>journalEntriesEMDR</option>
        <option>writeContainer</option>

        <option>recordAudio</option>
        <option>journalEntries</option>
        <option>homePage</option>

        <option>visitContainer</option>
        <option>visitSafeSpace</option>
        <option>writeDown</option>

      </Form.Control>
      <div style={{marginTop:"15px"}}></div>
      <Form.Label>Key Of Slide to Create</Form.Label>
      <div>
      <input
        value={slideName}
        onChange={(e) => {
          setSlideName(e.target.value);
        }}
      />
</div>

<div style={{marginTop:"50px"}}></div>
      <Button
        onClick={() => {
          if (slideType === "desc") {
            where[slideName] = {
              type: "desc",
              title: "This is a Test Page",
              complete: {
                title: "Back to Home",
                slide: "homePage",
              },
            };
            setWhere({...where});
          } else if (slideType === "yesNo") {
            where[slideName] = {
              type: "yesNo",
              title: "Did you want to visit your calm place?",
              yes: {
                slide: "homePage",
              },
              no: {
                slide: "homePage",
              },
            };
            setWhere({...where});
          } else if (slideType === "selectMood") {
            where[slideName] = {
              type: "selectMood",
              complete: {
                title: "Back to Home",
                slide: "homePage",
              },
            };
            setWhere({...where});
          } else if (slideType === "setSUDS") {
            where[slideName] = {
              type: "setSUDS",
              complete: {
                title: "Back to Home",
                slide: "homePage",
              },
            };
            setWhere({...where});
          } else if (slideType ===  "emdr_select"){
            where[slideName] = {
                type: "emdr_select",
              };
              setWhere({...where}); 
        } else if (slideType ===  "multiButton"){
            where[slideName] = {
                type: "multiButton",
                title: "Please Define Buttons",
                description: "",
                buttons: [
                  {
                    title: "Button One",
                    slide: "homePage",
                  },
                  {
                    title: "Button Two",
                    slide: "homePage",
                  },
                  {
                    title: "Button Three",
                    slide: "homePage",
                  },
                ],
              };
              setWhere({...where}); 
        } else if (slideType ===  "audioPlay"){
            where[slideName] = {
                type: "audioPlay",
                title: "Play an Audio",
                audio:
                  "https://storage.googleapis.com/savyn-media/Session%202%20-%20intro%20audio%20for%20all%20EMDR%20sessions.mp3",
                complete: {
                  title: "HomePage",
                  slide: "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "multiAudio"){
            where[slideName] = {
                type: "multiAudio",
                title: "Multiple Audios",
                description:
                  "Play Many Audios",
                audio: [
                  {
                    title: "1 Minute",
                    audio:
                      "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
                  },
                  {
                    title: "3 Minute",
                    audio:
                      "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
                  },
                  {
                    title: "7 Minute",
                    audio:
                      "https://storage.googleapis.com/savyn-media/English%20-%20Breathing%20Exercise%203%20mins.mp3",
                  },
                ],
                complete: {
                  title: "Continue",
                  slide: "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "counter"){
            where[slideName] = {
                type: "counter",
                title: "What for 60 seconds for the button",
                time: 60000,
                description: "",
                complete: {
                  slide:  "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "multiSelect"){
            where[slideName] = {
                type: "multiSelect",
                title: "Multi Select",
                description: "",
                buttons: [
                  {
                    title: "Home Page",
                    slide: "homePage",
                  },
                  {
                    title: "Home Page",
                    slide: "homePage",
                  },

                ],
                writeYourOwn: {
                  title: "Home Page",
                  slide: "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "video"){
            where[slideName] = {
                type: "video",
                title: "Play a Video",
                description: "",
                video:
                  "https://storage.googleapis.com/savyn-media/23%20Sec%20Slower%20EMDR%20Self%20Administered%20with%20528Hz%20Harmonics.mp4",
                complete: {
                  title: "Next",
                  slide:  "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "recordAudioEmdr"){

            where[slideName] = {
                type: "recordAudioEmdr",
                title:
                  "Record Audio",
                complete: {
                  slide: "homePage",
                },
                save: {
                  slide: "homePage",
                },
              };
              setWhere({...where}); 
        } else if (slideType ===  "scale"){

            where[slideName] = {
                type: "scale",
                title:
                  "Default Scale",
                description:
                  "Set Values",
                low: "Low",
                high: "High",
                min: 1,
                max: 7,
                step: 1,
                complete: {
                  slide: "homePage",
                },
    
              };
              setWhere({...where});



        } else if (slideType === "selectMood"){

            where[slideName] = {
                type: "selectMood",
                complete: {
                  slide: "homePage",
                },
              };
              setWhere({...where});
        
        } else if (slideType === "journalEntriesEMDR"){
            where[slideName] = {
                type: "journalEntriesEMDR",
                complete: {
                  title: "Home Page",
                  slide: "homePage",
                },
              };
              setWhere({...where});

        } else if (slideType === "writeContainer"){
            where[slideName] = {
                type: "writeContainer",
                title:
                  "Default",
                description: "",
            
                quit: {
                  slide: "homePage",
                },
                save: {
                  slide: "homePage",
                },
              };
              setWhere({...where});

        } else if (slideType === "recordAudio"){

            where[slideName] = {
                type: "recordAudioEmdr",
                title:
                  "Record Audio",
                complete: {
                  slide: "homePage",
                },
                save: {
                  slide: "homePage",
                },
              };
              setWhere({...where}); 

        } else if (slideType === "journalEntries"){
            where[slideName] = {
                type: "journalEntries",
                title: "Your written exposure therapy sessions",
                entries: [
                  {
                    title: "Session One ",
                    slideTitle: "Welcome to your written exposure therapy session",
                    slideDesc: "This is a Calm Place to record your feelings.",
                    slideWrite: "Let your mind flow and write what ever you want.",
                  },
                  {
                    title: "Fear Focus",
                    slideTitle: "Now Let's Focus on something more specific",
                    slideDesc: "This is a Calm Place to record your feelings.",
                    slideWrite: "What is the focus of your fear",
                  },
                ],
                complete: {
                  title: "Go to Homepage",
                  slide: "homePage",
                },
              };
              setWhere({...where}); 


        } else if (slideType === "visitContainer"){
            where[slideName] = {
                type:  "visitContainer",
              };
              setWhere({...where}); 


        } else if (slideType === "visitSafeSpace"){
            where[slideName] = {
                type: "visitSafeSpace",
              };
              setWhere({...where}); 

        } 




        }}
      >
        Add Slide
      </Button>

      <span style={{marginRight:"40px"}}/>

      <Button
          onClick={() => {

            var blob = new Blob(["let where ="+JSON.stringify(where, null, 2)], {type: "text/plain;charset=utf-8"});
FileSaver.saveAs(blob, "Where.jsx");

            
            //localStorage.setItem("hackAThon", JSON.stringify(where));
          }}
        >
          Save
        </Button>

      <EditPage
        where={where}
        setWhere={setWhere}
        slide={_slide}
        show={show}
        upDate={() => {
          setDateProcess(new Date().toString());
        }}
        setShow={setShow}
        handleClose={handleClose}
      />

        <Map where={where} setSlide={setSlide} slide={_slide}/>
    </div>
  );
};

export default Debugger;
