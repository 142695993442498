import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Range, getTrackBackground } from "react-range";
import { useState } from "react";
import { Row } from "react-bootstrap";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const STEP = 1;
const MIN = 0;
const MAX = 10;

const SetCalm = ({ state, control, slide }) => {
  const [values, setValues] = useState([5]);
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        {/* <Container className="d-flex  justify-content-center vh-100 mh-100 "> */}
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="mb-4">How did you feel using the calm place?</h3>
          <p className="mb-4"></p>
          <div>
            <Range
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => setValues(values)}
              renderMark={({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "16px",
                    width: "5px",
                    backgroundColor:
                      index * STEP < values[0] ? "#548BF4" : "#ccc",
                  }}
                />
              )}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values: values,
                        colors: ["#548BF4", "#ccc"],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "42px",
                    width: "42px",
                    borderRadius: "4px",
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "5px",
                      backgroundColor: isDragged ? "#548BF4" : "#CCC",
                    }}
                  />
                </div>
              )}
            />
            <div style={{ width: "100%", textAlign: "center" }}>
              <output style={{ marginTop: "30px" }}>
                {values[0].toFixed(0)}
              </output>
            </div>

            <label className="float-right mb-5">Completely Calm</label>
            <label className="float-left mb-5">Not Calm</label>
          </div>

          <button
            onClick={async() => {

              await addJSON({
                variables: {
                  json:JSON.stringify({
                    answer: values,
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  })
                }
              });
              
              control.goSlide(slide.complete);
            }}
            type="button"
            className="btn btn-block text-center btn-lg mb-2 btnStandard"
          >
            Continue
          </button>
        </div>
        {/* </div> */}
      </Row>
    </>
  );
};
export default SetCalm;
