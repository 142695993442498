import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";


import { useState } from "react";
import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";


const Video = ({ state, control, slide }) => {

  const [startTime, useStartTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left" style={{ width: "800px" }}>
          <h3 className="mb-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>

          <video width="100%" controls>
            <source src={slide.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={async () => {


              let {data} = await addJSON({
                variables: {
                  json:JSON.stringify({
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  })
                }
              });



              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title}
          </button>
        </div>
        {/* </div> */}
      </Row>
    </>
  );
};
export default Video;
