import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row, Dropdown } from "react-bootstrap";
import convertText from "../Helper/ConvertText";
import { BsArrowCounterclockwise, BsArrowClockwise } from "react-icons/bs";
import { IoPlayCircle, IoPauseCircle } from "react-icons/io5";
import { useState } from "react";

import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";

const AudioDropDown = ({ state, control, slide }) => {
  const [title, setTitle] = useState("");

  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());

  const [selectAudio, setSelectAudio] = useState(undefined);

  function playAudio() {
    const engAudio = document.getElementById("myAudio");
    const play = document.getElementById("playBtn");
    const pause = document.getElementById("pauseBtn");

    if (engAudio.paused) {
      engAudio.play();
      play.style = "display: none";
      pause.style = "display: block";
    } else {
      engAudio.pause();
      play.style = "display: block";
      pause.style = "display: none";
    }
  }
  const fwdAudio = () => {
    document.getElementById("myAudio").currentTime =
      document.getElementById("myAudio").currentTime + 15;
  };

  const rwdAudio = () => {
    document.getElementById("myAudio").currentTime =
      document.getElementById("myAudio").currentTime - 15;
  };

  let audioURL = state.audio[selectAudio];

  const [audioValue, setValue] = useState(0);
  const handleSelect = (e) => {
    setValue(e);

    var index = parseInt(e);
    var myAudio = document.getElementById("myAudio");
    var source = document.getElementById("audioSource");

    source.src = slide.audio[index].audio;

    myAudio.load();
    playAudio();
  };

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box">
          <h3 className="mb-4">{convertText(slide.title, state)}</h3>
          <p className="mb-4">{slide.description}</p>

          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              style={{ backgroundColor: "#004d99" }}
              id="dropdown-basic"
            >
              Select calm place
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {slide.audio.map((audio, index) => {
                return (
                  <div
                    key={audio.audio + index}
                    onClick={() => {
                      setSelectAudio(index);
                    }}
                  >
                    <Dropdown.Item eventKey={index}>
                      {audio.title}
                    </Dropdown.Item>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <div className="row justify-content-center my-4">
            <div className="row justify-content-center my-4">
              <button
                className="col-2 btn my-auto text-left controlSeconds"
                onClick={() => rwdAudio()}
              >
                <BsArrowCounterclockwise className="float-right audioControlBtn" />
                <small>
                  <b>15</b>
                </small>
              </button>

              <button
                className="col-4 btn my-auto mx-3 text-center"
                onClick={() => playAudio()}
              >
                <div id="playBtn" className="my-auto">
                  <IoPlayCircle className="playAudioBtn" />
                </div>
                <div
                  id="pauseBtn"
                  className="my-auto"
                  style={{ display: "none" }}
                >
                  <IoPauseCircle className="playAudioBtn" />
                </div>
              </button>

              <button
                className="col-2 btn my-auto text-right controlSeconds"
                onClick={() => fwdAudio()}
              >
                <BsArrowClockwise className="float-left audioControlBtn" />
                <small>
                  <b>15</b>
                </small>
              </button>
            </div>
          </div>

          <div className="text-center">
            <div style={{ maxWidth: "300px", margin: "auto" }}>
              <audio id="myAudio" controlsList="nodownload" controls>
               
                <source id="audioSource" src="" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          </div>

          <div className="mt-4 text-left">
            <p>Give it a name. Choose a cue word that best describes it.</p>
            <textarea
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="form-control border border-secondary mt-3"
              name=""
              rows="1"
            ></textarea>
          </div>

          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={async () => {


              await addJSON({
                variables: {
                  json:JSON.stringify({
                    answer: {
                      title
                    },
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  })
                }
              });


              control.saveAudioEntry(slide.audio[audioValue].audio, title);
              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title}
          </button>
        </div>
      </Row>
    </>
  );
};
export default AudioDropDown;
