import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { useState } from "react";
import { Form, Row, FormControl } from "react-bootstrap";

import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";

const EMDRJournal = ({ state, control, slide }) => {
  const [date, setDate] = useState("");
  const [distressingEvent, setDistressingEvent] = useState("");
  const [eventTrigger, setEventTrigger] = useState("");
  const [image, setImage] = useState("");
  const [belief, setBelief] = useState("");
  const [emotion, setEmotion] = useState("");
  const [scan, setScan] = useState("");
  const [distress, setDistress] = useState("");

  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());

  const updateJson = async()=>{

    await addJSON({
      variables: {
        json:JSON.stringify({
          answer: {
            date,
            distress,
            distressingEvent,
            eventTrigger,
            image,
            belief,
            emotion,
            scan
          },
          startTime,
          endTime:new Date().toISOString(),
          type: slide?.type,
          slide: slide?._slide,
        })
      }
    });
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="m-4">EMDR Journal</h3>
          <Form className="text-left">
            <Form.Group controlId="">
              <Form.Label>Date</Form.Label>
              <FormControl
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>
                Do you recall a moment in the past week that was mildy
                disturbing?
              </Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setDistressingEvent(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>What was the trigger?</Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setEventTrigger(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>
                What was the image that came to your mind?
              </Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setImage(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>What belief/ cognition emerged for you?</Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setBelief(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>What emotion did you feel?</Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setEmotion(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>
                Take a minute to do a body scan. Where do you feel it in your
                body? Notice the sensations you are feeling in your body and
                breifly write about it below
              </Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setScan(e.target.value);
                }}
              ></FormControl>
            </Form.Group>

            <Form.Group controlId="">
              <Form.Label>
                How distressed do you feel when you think about that incident
                now? Write your distress score, where 1 is the least distressed
                and 10 is the most distressed.
              </Form.Label>
              <FormControl
                as="textarea"
                onChange={(e) => {
                  setDistress(e.target.value);
                }}
              ></FormControl>
            </Form.Group>
          </Form>
          <button
            onClick={async() => {
              control.goSlide(slide.quit);
            }}
            type="button"
            className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
          >
            Quit
          </button>
          <button
            onClick={async () => {
              control.saveJournal(
                date,
                distressingEvent,
                eventTrigger,
                image,
                belief,
                emotion,
                scan,
                distress
              );
              updateJson();
              control.goSlide(slide.save);
            }}
            type="button"
            className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
          >
            Save
          </button>

          {slide.complete ? (
            <button
              type="button"
              className="btn btn-block border  text-center btn-lg mt-4"
              onClick={() => {
                control.goSlide(slide.complete);
              }}
            >
              Next
            </button>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
};
export default EMDRJournal;
