import Graph from "react-graph-vis";
var sha256 = require('js-sha256');

/*
[
        { id: 1, label: "Node 1", title: "node 1 tootip text" },
        { id: 2, label: "Node 2", title: "node 2 tootip text" },
        { id: 3, label: "Node 3", title: "node 3 tootip text" },
        { id: 4, label: "Node 4", title: "node 4 tootip text" },
        { id: 5, label: "Node 5", title: "node 5 tootip text" }
      ]*/
function Map({where, setSlide, slide}) {

    let nodes = {};
    let edges = {};
    for (const [key, value] of Object.entries(where)) {
        

        if (key===slide){
            nodes[key]={ id: key, label: key, title: key, color: "#FFA807" }
        }else{
            nodes[key]={ id: key, label: key, title: key, color: "#97C2FC" }
        }
  

        if (value.complete){
            if (value.complete.slide){
                if (value.complete.slide!=="homePage"){

                edges[key+"###"+value.complete.slide] = {id:key+"###"+value.complete.slide, from: key, to: value.complete.slide };
                }
            }
        }

        if (value.yes){
            if (value.yes.slide){
                if (value.yes.slide!=="homePage"){
                edges[key+"###"+value.yes.slide] = {id:key+"###"+value.yes.slide, from: key, to: value.yes.slide };
                }
            }
        }

        if (value.no){
            if (value.no.slide){
                if (value.no.slide!=="homePage"){
                edges[key+"###"+value.no.slide] = {id:key+"###"+value.no.slide, from: key, to: value.no.slide };
                }
            }
        }

        if (value.buttons){
            for (let index in value.buttons){
                let object = value.buttons[index];
                if (object.slide){
                    if (object.slide!=="homePage"){
                    edges[key+"###"+object.slide] = {id:key+"###"+object.slide, from: key, to: object.slide };
                    }
                }
            }
        }
    }

    const graph = {
      nodes:Object.values(nodes),
      edges:Object.values(edges)
    };
  
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      },
      height: "500px"
    };
  
    const events = {
      select: function(event) {
        var { nodes, edges } = event;
        if (nodes.length){

            setSlide(nodes[0]);
        }
      },
      hoverNode:function(event) {
        //var { nodes, edges } = event;
        console.log("Worked");
        console.log(event);
      },
    };
    return (
      <Graph
        key={sha256(JSON.stringify({nodes:Object.keys(nodes),edges}))}
        graph={graph}
        options={options}
        events={events}
        getNetwork={network => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property
        }}
      />
    );
  }


  export default Map;