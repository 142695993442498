import { useState } from "react";
import axios from 'axios';

const ChatBox = ({ state, control, slide })=>{

  return  <>
  <ChatBoxInner state={state} control={control} slide={slide}/>
  </>

}



const ChatBoxInner = ({ state, control, slide }) => {
  const [chat, setChat] = useState(false);
  const [currerntText, setCurrrentText] = useState("");


  /*
  const { loading, error, data } = useQuery(gqlQuery.getAForm, {
    variables: { id: match.params.Lookup },
  });
*/

  const [chatBlocks, setChatBlocks] = useState([
      ]);

  if (!chat) {
    return (
      <>
        {/*<div
          className="chat-popup"
          id="myForm"
          style={{
            position: "fixed",
            bottom: 50,
            right: "15px",
            border: "3px solid #f1f1f1",
            zIndex: 9,
          }}
        >
          <div
            style={{
              maxWidth: "300px",
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <button
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "16px 20px",
                border: "none",
                cursor: "pointer",
                width: "100%",
                marginBottom: "10px",
                opacity: "0.8",
              }}
              type="button"
              className="btn cancel"
              onClick={() => {
                setChat(true);
              }}
            >
              Get Help
            </button>
          </div>
        </div>*/}
      </>
    );
  }
  return (
    <>
      <div
        className="chat-popup"
        id="myForm"
        style={{
          position: "fixed",
          bottom: 50,
          right: "15px",
          border: "3px solid #f1f1f1",
          zIndex: 9,
        }}
      >
        <div
          style={{
            maxWidth: "300px",
            padding: "10px",
            backgroundColor: "white",
          }}
        >
          <h1>Get Help</h1>

          <label htmlFor="msg">
            <b>Message</b>
          </label>
          <textarea
          valeu={currerntText}
          onChange={(e)=>{
              setCurrrentText(e.target.value);
          }}
            placeholder="Type message.."
            name="msg"
            style={{
              width: "100%",
              padding: "15px",
              margin: "5px 0 22px 0",
              border: "none",
              background: "#f1f1f1",
              resize: "none",
              minHeight: "200px",
            }}
            required
          ></textarea>

          <div style={{maxHeight:"180px", marginBottom:"20px",overflowY:"scroll"}}>
              {
                  chatBlocks.map((e,index)=>{

                    return <div key={"Chat_"+index} style={{marginBottom:"10px"}}>
                        <div style={{marginBottom:"5px"}}>You</div>
                        <div>
                        {e.text}
                        </div>
                        
                    </div>

                  })
              }

          </div>

          <button
          onClick={()=>{
            setChatBlocks([...chatBlocks, {
                text:currerntText,
                poster:"me",
            }])

            axios({
                method: 'post',
                url: "https://hooks.slack.com/services/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",
                data: {
                    
                        "channel": "C01TQ7KU2LX",
                        "text":  currerntText
                      
                    
                   }
              });

            setCurrrentText("");

          }}
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "16px 20px",
              border: "none",
              cursor: "pointer",
              width: "100%",
              marginBottom: "10px",
              opacity: "0.8",
            }}
          >
            Send
          </button>
          <button
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "16px 20px",
              border: "none",
              cursor: "pointer",
              width: "100%",
              marginBottom: "10px",
              opacity: "0.8",
            }}
            type="button"
            className="btn cancel"
            onClick={() => {
                setChat(false);
              }}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};
export default ChatBox;
