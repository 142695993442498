import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";

import {
  Form,
  Row,
} from "react-bootstrap";

import { useState } from "react";


const TrumaCreator = ({ state, control, slide }) => {
  let [trigger, setTrigger] = useState("");
  let [noTrigger, setNoTrigger] = useState("");
  return (
    <>

<Navbar state={state} control={control} slide={slide}/>
      <Row className="bg-img min-vh-100" >
  
      <Sidepanel state={state} control={control} slide={slide}/>
      <div className="box text-left" >
     

        <h3>What would you like to work on today?</h3>

          <br />
          <Form>
   
         Indicate below which traumatic event still causes you the most distress.           List key events from your trauma history.
 
              {/* <Table responsive="sm">

              
                <th></th>
                <th></th>
              
          
            <tbody>
              <RowHistory
                index={0}
              />


              </tbody>
          </Table> */}


          <br />
          <textarea name="" id="" cols="50" rows="10"></textarea>
          {/* <Form.Control type="text" placeholder="" onChange={(e)=>{
                setTrigger(e.target.value);
              }} value={trigger}/> */}
          {/* <Form> */}
            <Form.Group controlId="userName">
              <Form.Label>Name of memory you will be working on today</Form.Label>
              <Form.Control type="text" placeholder="" onChange={(e)=>{
                setTrigger(e.target.value);
              }} value={trigger}/>
            </Form.Group>

            <Form.Group controlId="userName">
              <Form.Label>Write a less triggering name that will appear on the screen for you (optional)</Form.Label>
              <Form.Control type="text" placeholder="" onChange={(e)=>{
                setNoTrigger(e.target.value);
              }} value={noTrigger} />
            </Form.Group>

            <button
              onClick={() => {
                
                if (!noTrigger) {
                  control.setProcessTraumaTitle(trigger)
                } else {
                  control.setProcessTraumaTitle(noTrigger)
                }

                control.goSlide(slide.complete, {
                  trigger,noTrigger
                })
              }}
              type="button"
              className="btn btn-block text-center btn-lg mb-2 btnStandard"
            >
               {slide.complete.title}
            </button>
            <div style={{paddingTop:"50px"}}></div>
          </Form>
        </div>
        </Row>
      {/* </Container> */}
    </>
  );
};

export default TrumaCreator;


// then have another text box with title saying "select what you would like to work on today"
// "name of trauma you are working on for the therapist"
// "name for you(if you want it to be different)