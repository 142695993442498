import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import {
  Row,
} from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const ReviewEmdrJournal = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [selectJournal, setSelectJournal] = useState(undefined);

  if (!state.journals) {
    state.journals = [];
  }

  if (selectJournal !== undefined) {
    return (
      <>
        <Navbar state={state} control={control} slide={slide} />
        <Row className="bg-img min-vh-100">
          <Sidepanel state={state} control={control} slide={slide} />
          <div className="box text-left">
            <h3 className="text-center my-4">Journal</h3>
            <br />
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="1"
                value={state.journals[selectJournal].date}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="3"
                value={state.journals[selectJournal].distressingEvent}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="3"
                value={state.journals[selectJournal].eventTrigger}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="2"
                value={state.journals[selectJournal].image}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="1"
                value={state.journals[selectJournal].belief}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="2"
                value={state.journals[selectJournal].emotion}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="2"
                value={state.journals[selectJournal].scan}
                readOnly={true}
              ></textarea>
            </div>
            <div className="mb-4">
              <textarea
                onChange={(e) => {}}
                className="form-control border border-secondary mt-n3"
                name=""
                rows="2"
                value={state.journals[selectJournal].distress}
                readOnly={true}
              ></textarea>
            </div>
            <button
              type="button"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={async() => {

                await addJSON({
                  variables: {
                    json: JSON.stringify({
                      selectJournal,
                      startTime,
                      endTime: new Date().toISOString(),
                      type: slide?.type,
                      slide: slide?._slide,
                    }),
                  },
                });

                control.goWhere("reviewJournals");
                setSelectJournal(undefined);
              }}
            >
              {state.savePosition ? "Next" : "EMDR Journals"}
            </button>
          </div>
        </Row>
      </>
    );
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3>EMDR Journals</h3>
          <br />

          {state.journals.map((e, index) => {
            return (
              <div
                key={"journals" + index}
                className="btn btn-block  text-left btn-lg"
                onClick={() => {
                  setSelectJournal(index);
                }}
              >
                <i
                  className="fas fa-book-open mt-1 mr-2"
                  style={{ fontSize: "52px", color: "grey" }}
                ></i>
                Emdr Journal {index + 1}
              </div>
            );
          })}

          <button
            type="submit"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={() => {
              control.goWhere("homePage");
            }}
          >
            Done
          </button>
        </div>
      </Row>
      {/* </div>
        </div> */}
    </>
  );
};
export default ReviewEmdrJournal;
