import { Form } from "react-bootstrap";

const RowHistory = ({ index, event, answers= [], setAnswers=()=>{} }) => {
  return (
    <tr>
      <td>{event}</td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <Form.Check
          inline
          name={"radio" + index}
          label=""
          type="radio"
          id={"inline-radio-" + index}
          onChange={() => {
            setAnswers({ ...answers, [index]: 0 });
          }}
        />
      </td>
    </tr>
  );
};

const RowZeroHundred = ({ index, answers = [],  setAnswers=()=>{} }) => {
  return (
    <>
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 0 });
        }}
      />{" "}
      0% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 10 });
        }}
      />{" "}
      10% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 20 });
        }}
      />
      20% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 30 });
        }}
      />
      30% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 40 });
        }}
      />
      40% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 50 });
        }}
      />
      50% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 60 });
        }}
      />
      60% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 70 });
        }}
      />
      70% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 80 });
        }}
      />
      80% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 90 });
        }}
      />
      90% <span style={{ marginRight: "20px" }} />
      <Form.Check
        inline
        name={"radio__" + index}
        label=""
        type="radio"
        id={"inline-radio__" + index}
        style={{ marginRight: "0px" }}
        onChange={() => {
          setAnswers({ ...answers, [index]: 100 });
        }}
      />
      100% <span style={{ marginRight: "20px" }} />
    </>
  );
};

export { RowHistory, RowZeroHundred };
