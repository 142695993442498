import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";
const EMDRSelector = ({ state, control, slide }) => {
  if (!state.processTraumaTitle) {
    state.processTraumaTitle = [];
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center mb-4">Please choose your session</h3>
          <br />

          {(state.processTraumaTitle.length = 0)}

          {state.emdrSessions.map((e, index) => {
            return (
              <div
                key={"button:" + index}
                onClick={() => {
                  if (e.noTrigger !== "") {
                    control.setProcessTraumaTitle(e.noTrigger);
                    control.setEMDRSession(e.noTrigger);
                  } else {
                    control.setProcessTraumaTitle(e.trigger);
                    control.setEMDRSession(e.trigger);
                  }
                }}
                className="btn btn-block btn-lg mb-2 py-2 text-center btnStandard"
              >
                {e.noTrigger !== "" ? e.noTrigger : e.trigger}

              </div>
            );
          })}

          <div
            onClick={(e) => {
              control.goWhere("homePage");
            }}
            className="btn btn-block btn=lg w-100 mb-2 py-2 text-center btnStandard"
          >
            Go Homepage
          </div>
        </div>
      </Row>
    
    </>
  );
};
export default EMDRSelector;
