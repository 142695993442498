
import Navbar from '../Components/Navbar';
const Testing = ({ state, control, slide }) => {

  return (
    <>

      <Navbar state={state} control={control} slide={slide}/>

      {/* <div className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 " style={{ maxWidth: "25rem" }}>
        <div className=" flex-column w-100"> */}

<div className="wrapper " 
style={{
  display:"grid",
  height:"100vh",
  gridTemplateColumns: "200px auto",
  gridTemplateRows:"1fr auto 1fr",

  
  }}>
    <div className="sidebar"></div>
    <div className="content">
          <h3 className="text-center ">{slide.title}</h3><br/>
          <p className="mb-4">{slide.description}</p>

          <button
            type="button"
            className="btn btn-block border  text-center btn-lg mt-4"
            onClick={()=>{
              
            control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title?slide.complete.title:"Next"}
          </button>
         

          </div>
          </div>
          {/* <EmergencyResources /> */}
        {/* </div> */}
      {/* </div> */}
    </>
  );
};





export default Testing;
