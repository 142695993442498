import Navbar from "../Components/Navbar";
import { useState } from "react";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const WriteEntry = ({ state, control, slide }) => {
  const [title, setTitle] = useState("");
  const [doc, setDoc] = useState("");

  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="text-center my-4">{slide.title}</h3>
          <br />

          <textarea
            onChange={(e) => {
              setDoc(e.target.value);
            }}
            className="form-control border border-secondary mb-3 mt-n3"
            name=""
            rows="12"
          ></textarea>
          <p>Give it a name. Choose a cue word that best describes it.</p>
          <textarea
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className="form-control border border-secondary mt-3"
            name=""
            rows="1"
          ></textarea>

          <button
            onClick={() => {
              control.goSlide(slide.quit);
            }}
            type="button"
            className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
          >
            Quit
          </button>
          <button
            onClick={async () => {
              await addJSON({
                variables: {
                  json: JSON.stringify({
                    answer: { doc, title },
                    startTime,
                    endTime: new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  }),
                },
              });

              control.saveContainer(doc, title);
              control.goSlide(slide.save);
            }}
            type="button"
            className="btn float-right mt-3 mb-4 ml-2 btnStandardMini"
          >
            Save
          </button>

          {slide.complete ? (
            <button
              type="button"
              className="btn btn-block text-center btn-lg mt-4 btnStandard"
              onClick={() => {
                control.goSlide(slide.complete);
              }}
            >
              Next
            </button>
          ) : (
            <></>
          )}
        </div>
      </Row>
      {/* </div>
      </div> */}
    </>
  );
};

export default WriteEntry;
