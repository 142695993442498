import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";
import { BsArrowCounterclockwise, BsArrowClockwise } from "react-icons/bs";
import {
  IoPlayCircle,
  IoPauseCircle,
} from "react-icons/io5";

import { useState } from "react";
import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";

const MultiAudio = ({ state, control, slide }) => {

  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box">
          <h3 className="mb-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>

          {slide.audio.map((audio, index) => {
            const myAudio = "myAudio" + index;
            const playBtn = "playBtn" + index;
            const pauseBtn = "pauseBtn" + index;
            function playAudio() {
              const engAudio = document.getElementById(myAudio);
              const play = document.getElementById(playBtn);
              const pause = document.getElementById(pauseBtn);

              if (engAudio.paused) {
                engAudio.play();
                play.style = "display: none";
                pause.style = "display: block";
              } else {
                engAudio.pause();
                play.style = "display: block";
                pause.style = "display: none";
              }
            }

            const fwdAudio = () => {
              document.getElementById(myAudio).currentTime =
                document.getElementById(myAudio).currentTime + 15;
            };

            const rwdAudio = () => {
              document.getElementById(myAudio).currentTime =
                document.getElementById(myAudio).currentTime - 15;
            };

            return (
              <div key={audio.audio + index}>
                <div className="text-left">
                  <h2
                    style={{ fontSize: "18px" }}
                    className=" mt-2 mb-2 float-left"
                  >
                    {audio.title}
                  </h2>
                </div>
                <br />

                <div style={{ maxWidth: "300px", margin: "auto" }}>
                  <div className="row justify-content-center my-4">
                    <div className="row justify-content-center align-items-center my-3">
                      <button
                        className="col-2 btn my-auto text-left controlSeconds"
                        onClick={() => rwdAudio()}
                      >
                        <BsArrowCounterclockwise className="float-right audioControlBtn" />
                        <small>
                          <b>15</b>
                        </small>
                      </button>

                      <button
                        className="col-4 btn my-auto mx-3 text-center"
                        onClick={() => playAudio()}
                      >
                        <div id={playBtn} className="my-auto">
                          <IoPlayCircle className="playAudioBtn" />
                        </div>
                        <div
                          id={pauseBtn}
                          className="my-auto"
                          style={{ display: "none" }}
                        >
                          <IoPauseCircle className="playAudioBtn" />
                        </div>
                      </button>

                      <button
                        className="col-2 btn my-auto text-right controlSeconds"
                        onClick={() => fwdAudio()}
                      >
                        <BsArrowClockwise className="float-left audioControlBtn" />
                        <small>
                          <b>15</b>
                        </small>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <div style={{ maxWidth: "300px", margin: "auto" }}>
                    <audio id={myAudio} controlsList="nodownload" controls>
                      <source src={audio.audio} type="audio/mpeg" />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                </div>
              </div>
            );
          })}
          <button
            type="button"
            className="btn btn-block text-center btn-lg mt-4 btnStandard"
            onClick={async () => {
              await addJSON({
                variables: {
                  json: JSON.stringify({
                    startTime,
                    endTime: new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                  }),
                },
              });
              control.goSlide(slide.complete);
            }}
          >
            {slide.complete.title}
          </button>
        </div>
      </Row>
    </>
  );
};
export default MultiAudio;
