import Navbar from "../Components/Navbar";
import { useState } from "react";
import SetSUDS from "../Templates/SetSUDS";

import { InputGroup, FormControl } from "react-bootstrap";

import gqlQuery from '../GQL';
import { useMutation } from "@apollo/client";


const JournalEntries = ({ state, control, slide }) => {
  let [selectedSlide, setSelectedSlide] = useState(undefined);
  let [goIn, setGoIn] = useState(false);
  let [checkSuds, setCheckSuds] = useState(false);
  let [text, setText] = useState("");

  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);

  if (checkSuds) {

    //let writtenExpose = slide.entries[selectedSlide];

    return (
      <>
        <SetSUDS state={state} control={control} slide={slide} />
        <button
          type="button"
          className="btn btn-block border text-center btn-lg mb-2"
          onClick={() => {
            setCheckSuds(false);
            setGoIn(true);
          }}
        >
          Next
        </button>

        <div className=" flex-column w-100"></div>
      </>
    );
  }
  if (goIn) {
    let writtenExpose = slide.entries[selectedSlide];

    return (
      <>
        <Navbar state={state} control={control} slide={slide} />

        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{ maxWidth: "25rem" }}
        >
          <div className=" flex-column w-100">
            <h3>{writtenExpose.slideWrite}</h3>

            <InputGroup>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                onChange={(e) => {
                  setText(e.target.value);
                }}
                value={text}
              />
            </InputGroup>

            <button
              type="submit"
              className="btn btn-block border  text-center btn-lg mt-4"
              onClick={async () => {
                await addJSON({
                  variables: {
                    json: JSON.stringify({
                      answer: {text, selectedSlide},
                      startTime,
                      endTime: new Date().toISOString(),
                      type: slide?.type,
                      slide: slide?._slide,
                    }),
                  },
                });
                control.addWrittenExposure(text, selectedSlide);
                setGoIn(false);
                setSelectedSlide(undefined);
                setText("");
              }}
            >
              Save Doc
            </button>
          </div>
        </div>
      </>
    );
  }

  if (selectedSlide !== undefined) {
    // console.log("hello")

    let writtenExpose = slide.entries[selectedSlide];

    return (
      <>
        <Navbar state={state} control={control} slide={slide} />

        <div
          className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
          style={{ maxWidth: "25rem" }}
        >
          <div className=" flex-column w-100">
            <h3>{writtenExpose.slideTitle}</h3>
            <br />
            <p>{writtenExpose.slideDesc}</p>

            <button
              type="submit"
              className="btn btn-block border  text-center btn-lg mt-4"
              onClick={() => {
                setText(state.writtenExposure[selectedSlide]);
                console.log(selectedSlide);
                setGoIn(true);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />

      <div
        className="  container d-flex  justify-content-center align-items-center vh-100 mh-100 "
        style={{ maxWidth: "25rem" }}
      >
        <div className=" flex-column w-100">
          <h3>{slide.title}</h3>
          <br />
          {state.wetSessions.map((e, index) => {
            return (
              <div
                key={"button:" + index}
                onClick={() => {
                  control.setWETSession(e.index);
                }}
                className="btn border border-secondary w-100 mb-2 py-3 text-left"
              >
                {e.index}
              </div>
            );
          })}

          <div
            onClick={(e) => {
              control.goWhere("homePage");
            }}
            className="btn border border-secondary w-100 mb-2 py-3 text-left"
          >
            Go Homepage
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default JournalEntries;
