import Navbar from "../Components/Navbar";
import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";
import { useState } from "react";
const YesNo = ({ state, control, slide }) => {
  // let [cpEntry, setCPEntry] = useState("");
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [startTime] = useState(new Date().toISOString());
  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="mb-4">{slide.title}</h3>
          <p className="mb-4">{slide.description}</p>
          <button
            type="button"
            className="btn btn-block text-center btn-lg mb-2 btnStandard"
            onClick={async () => {
              await addJSON({
                variables: {
                  json:JSON.stringify({
                    answer:"YES",
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                    title:slide.title
                  })
                }
              });
              control.goSlide(slide.yes);


              // control.cpEntry(true);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-block text-center btn-lg mb-2 btnStandard"
            onClick={async () => {
              await addJSON({
                variables: {
                  json:JSON.stringify({
                    answer:"YES",
                    startTime,
                    endTime:new Date().toISOString(),
                    type: slide?.type,
                    slide: slide?._slide,
                    title:slide.title
                  })
                }
              });
              control.goSlide(slide.no);
              // control.cpEntry(false);
            }}
          >
            No
          </button>
        </div>
      </Row>
    </>
  );
};
export default YesNo;
