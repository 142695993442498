import Navbar from "../Components/Navbar";
import { Range, getTrackBackground } from "react-range";

import Sidepanel from "../Savyn/Sidepanel";
import { Row } from "react-bootstrap";

import { useState } from "react";
import gqlQuery from "../GQL";
import { useMutation } from "@apollo/client";

const PositiveBelief = ({ state, control, slide }) => {
  const [startTime] = useState(new Date().toISOString());
  const [addJSON] = useMutation(gqlQuery.addJSON);
  const [answer] = useState(0);

  if (!state.posBelief) {
    state.posBelief = [];
  }

  const [values, setValues] = useState([5]);

  const STEP = slide.step ? slide.step : 7;
  const MIN = slide.min ? slide.min : 1;
  const MAX = slide.max ? slide.max : 7;


  const changeSlide = async (save) => {
    await addJSON({
      variables: {
        json: JSON.stringify({
          answer: { belief:state.posBelief[answer], values },
          startTime,
          endTime: new Date().toISOString(),
          type: slide?.type,
          slide: slide?._slide,
        }),
      },
    });
  };

  return (
    <>
      <Navbar state={state} control={control} slide={slide} />
      <Row className="bg-img min-vh-100">
        <Sidepanel state={state} control={control} slide={slide} />
        <div className="box text-left">
          <h3 className="mb-4">{slide.title}</h3>

          {answer !== undefined
            ? console.log(state.posBelief[answer])
            : console.log("false")}

          <p className="mb-4">
            {" "}
            When you think about the incident, how true is it in your life
            today? {state.posBelief[answer]}, on a scale of 1 to 7 where 1 feels
            completely false and 7 feels completely true?
          </p>

          <div>
            <Range
              values={values}
              step={slide.step}
              min={slide.min}
              max={slide.max}
              onChange={(values) => setValues(values)}
              renderMark={({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "16px",
                    width: "5px",
                    backgroundColor:
                      index * STEP < values[0] ? "#548BF4" : "#ccc",
                  }}
                />
              )}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "36px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values: values,
                        colors: ["#548BF4", "#ccc"],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "42px",
                    width: "42px",
                    borderRadius: "4px",
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "5px",
                      backgroundColor: isDragged ? "#548BF4" : "#CCC",
                    }}
                  />
                </div>
              )}
            />
            <div style={{ width: "100%", textAlign: "center" }}>
              <output style={{ marginTop: "30px" }}>
                {values[0].toFixed(0)}
              </output>
            </div>
            <label className="float-left mb-5">{slide.low}</label>
            <label className="float-right mb-5">{slide.high}</label>
          </div>

          <button
            onClick={async () => {
              await changeSlide();
              control.goSlide(slide.complete);
            }}
            type="button"
            className="btn btn-block text-center btn-lg mb-2 btnStandard"
          >
            Continue
          </button>
        </div>
      </Row>
    </>
  );
};
export default PositiveBelief;
